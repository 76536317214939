<mat-card>
    <mat-card-title>
       <mat-icon color="primary">people_alt</mat-icon> Elenco Pazienti
    </mat-card-title>
    <mat-card-subtitle>
        In questa sezione puoi inserire nuovi pazienti o gestire gli esistenti
    </mat-card-subtitle>
    <mat-card-content>
        <!-- SEZIONE INSERIMENTO NUOVO PAZIENTE -->
        <mat-card>
            <mat-card-subtitle>
                <mat-icon style="color: green">person_add</mat-icon> &nbsp;Inserisci o Modifica Paziente
            </mat-card-subtitle>
            <mat-card-content>
                <form [formGroup]="PazientiForm" (ngSubmit)="Salva()">
                <div fxLayout="row" fxLayout.xs="column" fxFlexFill fxLayoutGap="10px">
                    <div fxFlex="25%" fxFlexFill >
                        <mat-form-field class="example-full-width">
                            <mat-label>Cognome</mat-label>
                            <input matInput [(ngModel)]="paziente.cognome" name="paziente.cognome" formControlName="cognome" required>
                        </mat-form-field>
                        <div *ngIf="submitted && f.cognome.errors" class="invalid-feedback">
                            <span *ngIf="f.cognome.errors.required">Campo obbligatorio</span>
                        </div>
                    </div>
                    <div fxFlex="25%" fxFlexFill >
                        <mat-form-field class="example-full-width">
                            <mat-label>Nome</mat-label>
                            <input matInput [(ngModel)]="paziente.nome" name="paziente.nome" formControlName="nome" required>
                        </mat-form-field>
                        <div *ngIf="submitted && f.nome.errors" class="invalid-feedback">
                            <span *ngIf="f.nome.errors.required">Campo obbligatorio</span>
                        </div>
                    </div>
                    <div fxFlex="25%" fxFlexFill >
                        <mat-form-field >
                            <mat-label>Data di Nascita</mat-label>
                            <input type="date" matInput [(ngModel)]="paziente.data_di_nascita" name="paziente.data_di_nascita" formControlName="data_di_nascita" required>
                        </mat-form-field>
                        <div *ngIf="submitted && f.data_di_nascita.errors" class="invalid-feedback">
                            <span *ngIf="f.data_di_nascita.errors.required">Campo obbligatorio</span>
                        </div>
                    </div>
                    <div fxFlex="25%" fxFlexFill >
                        <mat-form-field class="example-full-width">
                            <mat-label>Codice Fiscale</mat-label>
                            <input matInput [(ngModel)]="paziente.cf" name="paziente.cf" formControlName="cf" >
                        </mat-form-field>
                        <div *ngIf="submitted && f.cf.errors" class="invalid-feedback">
                            <span *ngIf="f.cf.errors.maxlength || f.cf.errors.minlength">Lunghezza non corretta, devi inserire 16 caratteri</span>
                        </div>
                    </div>
                </div>
                <div fxLayout="row" fxLayout.xs="column" fxFlexFill fxLayoutGap="10px">
                    <div fxFlex="15%" fxFlexFill >
                        <mat-form-field class="example-full-width">
                            <mat-label>Città e CAP</mat-label>
                            <mat-icon matPrefix color="accent">location_city</mat-icon>
                            <input matInput [(ngModel)]="paziente.citta" name="paziente.citta" formControlName="citta" >
                        </mat-form-field>
                    </div>
                    <div fxFlex="25%" fxFlexFill >
                        <mat-form-field class="example-full-width">
                            <mat-label>Indirizzo</mat-label>
                            <mat-icon matPrefix color="accent">place</mat-icon>
                            <input matInput [(ngModel)]="paziente.indirizzo" name="paziente.indirizzo" formControlName="indirizzo" >
                        </mat-form-field>
                    </div>
                    <div fxFlex="25%" fxFlexFill>
                        <mat-form-field class="example-full-width">
                            <mat-label>E-mail</mat-label>
                            <mat-icon matPrefix color="accent">alternate_email</mat-icon>
                            <input matInput type="email" [(ngModel)]="paziente.email" name="paziente.email" formControlName="email">
                        </mat-form-field>
                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                            <span *ngIf="f.email.errors.email">Inserisci un dindirizzo email valido</span>
                        </div>
                    </div>
                    <div fxFlex="15%" fxFlexFill>
                        <mat-form-field class="example-full-width">
                            <mat-label>Telefono</mat-label>
                            <span matPrefix color="accent">+39 &nbsp;</span>
                            <input matInput type="tel" [(ngModel)]="paziente.telefono" name="paziente.telefono" formControlName="telefono" required>
                        </mat-form-field>
                        <div *ngIf="submitted && f.telefono.errors" class="invalid-feedback">
                            <span *ngIf="f.telefono.errors.required">Campo obbligatorio</span>
                        </div>
                    </div>
                    <div fxFlex="20%" fxFlexFill>
                        <mat-form-field>
                            <mat-label>Comunicazioni</mat-label>
                            <mat-select [(ngModel)]="paziente.preferenza_com" name="paziente.preferenza_com" formControlName="preferenza">
                              <mat-option value='telefono'>Telefono</mat-option>
                              <mat-option value='email' >E-mail</mat-option>
                            </mat-select>
                          </mat-form-field>
                    </div>
                </div>
                <div fxLayout="row" fxLayout.xs="column" fxFlexFill fxLayoutGap="10px">
                    <div fxFlex="100%">
                        <button fxFlexFill mat-raised-button type="submit" color="primary">Salva</button>
                    </div>
                </div>
            </form>
            </mat-card-content>
        </mat-card>
        <!-- FINE SEZIONE INSERIMENTO NUOVO PAZIENTE -->
        <p></p>
        <div fxLayout="row" fxLayout.xs="column">
            <div fxFlex="70%">
                <mat-form-field class="example-full-width">
                    <mat-label>Filtra</mat-label>
                    <input matInput (keyup)="applyFilter($event)" placeholder="Filtro per cognome, nome, ecc." #input>
                  </mat-form-field>
            </div>
        </div>
        <!-- SEZIONE TABELLA PAZIENTI -->
        <table mat-table [dataSource]="elencoPaziente" matSort multiTemplateDataRows>

            <ng-container matColumnDef="codice">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> CODICE </th>
              <td mat-cell *matCellDef="let element" > <!--<ngx-barcode [id]="element.codice" [bc-height]="40" [bc-width]="2" [bc-value]="element.codice" [bc-display-value]="true"></ngx-barcode>-->{{element.codice}}</td>
            </ng-container>
          
            <ng-container matColumnDef="cognome">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Cognome </th>
              <td mat-cell *matCellDef="let element"> {{element.cognome}} </td>
            </ng-container>
          
            <ng-container matColumnDef="nome">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Nome </th>
              <td mat-cell *matCellDef="let element"> {{element.nome}} </td>
            </ng-container>

            <ng-container matColumnDef="data_di_nascita">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Data di nascita </th>
              <td mat-cell *matCellDef="let element"> {{element.data_di_nascita | date: 'dd-MM-yyyy'}} </td>
            </ng-container>

            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef> E-mail </th>
                <td mat-cell *matCellDef="let element"> {{element.email}} </td>
            </ng-container>

            <ng-container matColumnDef="telefono">
                <th mat-header-cell *matHeaderCellDef> Telefono </th>
                <td mat-cell *matCellDef="let element"> {{element.telefono}} </td>
            </ng-container>

            <ng-container matColumnDef="azioni">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Azioni </th>
                <td mat-cell *matCellDef="let element">
                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                        <mat-icon>more_vert</mat-icon>
                    </button> 
                    <mat-menu #menu="matMenu">
                        <button (click)="Elimina(element)" mat-menu-item><mat-icon mat-list-icon style="color: red">delete</mat-icon>Elimina</button>
                        <button (click)="Modifica(element)" mat-menu-item><mat-icon mat-list-icon style="color:goldenrod">edit</mat-icon>Modifica</button>
                        <!--<button (click)="StampaCodice(element.codice)" mat-menu-item><mat-icon mat-list-icon style="color:green">print</mat-icon>Stampa Codice</button>-->
                        <button (click)="StampaQr(element)" mat-menu-item><mat-icon mat-list-icon style="color:green">print</mat-icon>Stampa Qr</button>
                    </mat-menu>
                </td>
              </ng-container>

              <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                  <div [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'" class="example-element-detail">
                    <div fxLayout="row" fxFlexFill fxLayoutGap="10px">
                        <div fxFlex="100%">
                            <mat-card>
                                <mat-card-content>
                                    <b>CODICE FISCALE: </b> {{element.cf}} - <b>INDIRIZZO: </b> {{element.indirizzo}}, {{element.citta}} - <b>PREFERENZA COMUNICAZIONI: </b>{{element.preferenza_com}}
                                </mat-card-content>
                            </mat-card>
                            <div fxLayout="row" fxFlexFill fxLayoutGap="10px">
                                <div fxFlex="33%" >
                                    <mat-card *ngIf="verifica_iscrizioni">
                                        <mat-card-title>Iscrizioni e Presenze</mat-card-title>
                                        <mat-card-content>
                                            <div fxLayout="row" *ngFor="let iscrizione of verifica_iscrizioni.iscrizioni">
                                                <div fxFlex="100%">
                                                    <b>{{iscrizione.descrizione}} - Inizio: {{iscrizione.data_inizio | date: 'dd/MM/yyyy'}} - Rimanenti: {{iscrizione.rimanenti}}</b>
                                                    <p>{{iscrizione.presenze.length}} presenze:</p>
                                                    <p *ngFor="let presenza of iscrizione.presenze; let i = index"><i>#{{i+1}} - {{presenza | date:'dd/MM/yyyy'}}</i></p>
                                                </div>
                                            </div>
                                        </mat-card-content>
                                    </mat-card>
                                </div>
                                <div fxFlex="33%">
                                     <mat-card>
                                         <mat-card-title>Cartella Clinica</mat-card-title>
                                         <mat-card-content>
                                            <div *ngIf="array_cartelle_cliniche.length==0" fxLayout="row">
                                                <div fxFlex="20%">
                                                    <button mat-mini-fab color="primary" (click)="OpenCartella(element.id_paziente, false, '')"><mat-icon>add</mat-icon></button>
                                                </div>
                                                <div fxFlex="80%">
                                                    Non sono presenti cartelle cliniche per questo paziente.
                                                </div>
                                            </div>
                                            <div *ngFor="let cc of array_cartelle_cliniche" fxLayout="row" style="border-bottom: 1px solid orange; padding-top:8px;">
                                                <div fxFlex="50%">
                                                    Ultima modifica: {{cc.data | date:'dd/MM/yyyy hh:mm'}} 
                                                </div>
                                                <div fxFlex="50%"> 
                                                    <button (click)="service.GeneraCartella(cc, element)" mat-icon-button><mat-icon style="color:coral">open_in_new</mat-icon></button>
                                                    <button mat-raised-button color="primary" *ngIf="!cc.firma" (click)="OpenCartella(element.id_paziente, true, cc)" >Apponi firma</button>
                                                    <button mat-raised-button color="primary" (click)="AggiornaCartella(element.id_paziente, false, cc)" >Cancella Firma e Aggiorna</button>
                                                </div>
                                            </div>
                                            <hr/>
                                             <div *ngIf="array_file.length==0" fxLayout="row">
                                                 <div fxFlex="100%">
                                                     Non sono presenti allegati per questo paziente.
                                                 </div>
                                             </div>
                                            <div *ngFor="let ffile of array_file" fxLayout="row" style="border-bottom: 1px solid orange; padding-top:8px;">
                                                <div fxFlex="80%">
                                                    {{ffile.nome}} 
                                                </div>
                                                <div fxFlex="20%"> 
                                                    <button (click)="service.OpenFile(ffile)" mat-icon-button><mat-icon style="color:coral">open_in_new</mat-icon></button>
                                                    <button (click)="deleteFileInput(ffile)" mat-icon-button><mat-icon style="color:red">delete</mat-icon></button>
                                                </div>
                                            </div>
                                            <hr/>
                                             <div fxLayout="row">
                                                <div fxFlex="80%">
                                                        <mat-label>Inserisci un nuovo allegato (max 3mb)&nbsp;</mat-label>
                                                        <input type="file"
                                                        id="file"
                                                        (change)="changeFileInput($event.target.files)">
                                                        
                                                </div>
                                                <div fxFlex="20%"> 
                                                    <button (click)="handleFileInput(element)" mat-icon-button *ngIf="!disabled_button"><mat-icon style="color:coral">cloud_upload</mat-icon></button>
                                                    
                                                </div>
                                             </div>
                                             <div fxLayout="row">
                                                <div fxFlex="80%">
                                                    <span style="color: red; font-style: italic;" *ngIf="disabled_button && fileToUpload">Le dimensioni del file superano il limite consentito</span>
                                                </div>
                                             </div>
                                           
                                         </mat-card-content>
                                     </mat-card>
                                 </div>
                                 <div fxFlex="34%">
                                    <mat-card>
                                        <mat-card-title>Fatture</mat-card-title>
                                        <mat-card-content>
                                            <div *ngFor="let element of fatture_paziente" fxLayout="row" style="border-bottom: 1px solid orange; padding-top:8px;">
                                                <div fxFlex="80%">
                                                    {{element.tipo_fattura}} n°{{element.numero_fattura}} del {{element.data_fattura | date:'dd/MM/yyyy'}} 
                                                </div>
                                                <div fxFlex="20%"> 
                                                    <button (click)="StampaFattura(element,1)" mat-icon-button><mat-icon style="color:coral">print</mat-icon></button>
                                                    <button (click)="StampaFattura(element,0)" mat-icon-button><mat-icon style="color: red">picture_as_pdf</mat-icon></button>
                                                </div>
                                            </div>
                                        </mat-card-content>
                                    </mat-card>
                                 </div>
                            </div>
                        </div>
                    </div>
                  </div>
                </td>
              </ng-container>
          
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns;" 
            class="example-element-row"
            [class.example-expanded-row]="expandedElement === element" 
            (click)="MostraDettagli(element)"></tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']"></tr>
          </table>
          <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
        <!-- FINE SEZIONE TABELLA PAZIENTI -->
    </mat-card-content>
</mat-card>
