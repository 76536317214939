import { Component, OnInit, Inject } from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { GlobalServices } from '../_services';
import {Prestazioni, RESPONSE} from '../_models';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface DialogData {
  prestazione: Prestazioni;
}

@Component({
  selector: 'app-prestazioni',
  templateUrl: './prestazioni.component.html',
  styleUrls: ['./prestazioni.component.scss']
})


export class PrestazioniComponent implements OnInit {
  
  prest= new Array<Prestazioni>();
  prestazione=new Prestazioni();
  displayedColumns: string[] = ['id_prestazione', 'descrizione','descrizione_fattura', 'durata', 'prezzo', 'azioni'];
  elencoPrestazioni = new MatTableDataSource(this.prest);

  constructor(private service: GlobalServices, private _snackBar: MatSnackBar, public dialog: MatDialog) { }

  ngOnInit() {
    this.CaricaPrestazioni();
    
  }

  CaricaPrestazioni()
  {
    this.service.get_function(this.service.apiPrestazioni).subscribe(
      (prestazioni: Prestazioni[])=>
      {
        this.elencoPrestazioni=new MatTableDataSource(prestazioni);
      }
    )
  }

  Salva(){
    this.service.post_function(JSON.stringify(this.prestazione), this.service.apiPrestazioni).subscribe(
      (data: RESPONSE)=> {
        if(data.data='OK')
        this._snackBar.open('Operazione eseguita correttamente', 'OK', {
          duration: 3000,
          verticalPosition: 'top'
        });
        this.prestazione=new Prestazioni();
        this.CaricaPrestazioni();
      }
    )
  }

  Elimina(element){

    const dialogRef = this.dialog.open(DialogOverviewPrestazioniDialog, {
      width: '250px',
      data: {prestazione: element}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) 
      this.service.delete_function(this.service.apiPrestazioni+element.id_prestazione).subscribe(
        (data: RESPONSE)=> {
          if(data.data='OK')
          this._snackBar.open('Operazione eseguita correttamente', 'OK', {
            duration: 3000,
            verticalPosition: 'top'
          });
          this.prestazione=new Prestazioni();
          this.CaricaPrestazioni();
        }
      )
    });


    
    
  }

  Modifica(element){
    this.prestazione=element;
  }

}

@Component({
  selector: 'dialog-overview-example-dialog',
  templateUrl: 'dialog-overview-example-dialog.html',
})
export class DialogOverviewPrestazioniDialog {

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewPrestazioniDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

}
