<mat-card>
    <mat-card-title>
        <mat-icon color="primary">self_improvement</mat-icon>Elenco Prestazioni
    </mat-card-title>
    <mat-card-subtitle>
        Elenco delle prestazioni prenotabili con possibilità di aggiornamento
    </mat-card-subtitle>
    <mat-card-content>
        <mat-card>
            <mat-card-subtitle>Inserisci/Modifica Prestazione</mat-card-subtitle>
            <mat-card-content>
                <div fxLayout="row" fxLayout.xs="column" fxFlexFill fxLayoutGap="10px">
                    <div fxFlex="25%" >
                        <mat-form-field class="example-full-width">
                            <mat-label>Descrizione</mat-label>
                            <input matInput [(ngModel)]="prestazione.descrizione" name="prestazione.descrizione">
                        </mat-form-field>
                    </div>
                    <div fxFlex="40%" >
                        <mat-form-field class="example-full-width">
                            <mat-label>Ulteriore riga per fattura</mat-label>
                            <input matInput [(ngModel)]="prestazione.descrizione_fattura" name="prestazione.descrizione_fattura">
                        </mat-form-field>
                    </div>
                    <div fxFlex="10%">
                        <mat-form-field >
                            <mat-label>Durata</mat-label>
                            <input type="time" matInput [(ngModel)]="prestazione.durata" name="prestazione.durata">
                        </mat-form-field>
                    </div>
                    <div fxFlex="10%">
                        <mat-form-field >
                            <mat-label>Prezzo</mat-label>
                            <span matPrefix>€ &nbsp;</span>
                            <input type="number" min="0" step="0.5" matInput [(ngModel)]="prestazione.prezzo" name="prestazione.prezzo">
                        </mat-form-field>
                    </div>
                    <div fxFlex="15%">
                        <button mat-raised-button (click)="Salva()" color="primary">Salva</button>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
        <table mat-table [dataSource]="elencoPrestazioni" matSort>

            <ng-container matColumnDef="id_prestazione">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
              <td mat-cell *matCellDef="let element"> {{element.id_prestazione}} </td>
            </ng-container>
          
            <ng-container matColumnDef="descrizione">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Descrizione </th>
              <td mat-cell *matCellDef="let element"> {{element.descrizione}} </td>
            </ng-container>

            <ng-container matColumnDef="descrizione_fattura">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Ulteriore riga per Fattura </th>
                <td mat-cell *matCellDef="let element"> {{element.descrizione_fattura}} </td>
              </ng-container>
          
            <ng-container matColumnDef="durata">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Durata </th>
              <td mat-cell *matCellDef="let element"> {{element.durata}} </td>
            </ng-container>

            <ng-container matColumnDef="prezzo">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Prezzo </th>
              <td mat-cell *matCellDef="let element"> € {{element.prezzo}} </td>
            </ng-container>

            <ng-container matColumnDef="azioni">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Azioni </th>
                <td mat-cell *matCellDef="let element">
                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                        <mat-icon>more_vert</mat-icon>
                    </button> 
                    <mat-menu #menu="matMenu">
                        <button (click)="Elimina(element)" mat-menu-item><mat-icon mat-list-icon style="color: red">delete</mat-icon>Elimina</button>
                        <button (click)="Modifica(element)" mat-menu-item><mat-icon mat-list-icon style="color:goldenrod">edit</mat-icon>Modifica</button>
                    </mat-menu>
                </td>
              </ng-container>
          
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
    </mat-card-content>
</mat-card>
