import { Component } from '@angular/core';
import { first } from 'rxjs/operators';

import { User } from '../_models';
import { UserService, AuthenticationService } from '../_services';
import {Router} from '@angular/router';

@Component({ templateUrl: 'home.component.html',styleUrls: ['./home.component.scss'] })
export class HomeComponent {
    loading = false;
    users: User[];
    over: any;
    flex_items;

    constructor(private userService: UserService, private _authService: AuthenticationService, private router: Router) { }

    ngOnInit() {

      this.flex_items=100/(this.menu.length+1);
     
    }
    menu: Object[] = [
      {
        title: 'Bacheca',
        route: '/',
        icon: 'dashboard',
        color: 'green'
      },
      {
        title: 'Calendario',
        route: 'calendario',
        icon: 'date_range',
        color: 'green'
      },
      {
        title: 'Elenco Prestazioni',
        route: '/prestazioni',
        icon: 'group_work',
        color: 'pink'
      },
      {
        title: 'Elenco Pazienti',
        route: '/pazienti',
        icon: 'people_alt',
        color: 'orange'
      },
      {
        title: 'Impostazione Corsi',
        route: '/corsi',
        icon: 'table_view',
        color: 'pink'
      },
      {
        title: 'Gestisci Iscrizioni',
        route: '/iscrizioni',
        icon: 'book_online',
        color: 'goldenrod'
      },
      {
        title: 'Fatture',
        route: '/fatture',
        icon: 'chrome_reader_mode',
        color: 'powderblue'
      },
    ]

    logout(){
      this._authService.logout();
      this.router.navigate(['/login']);
    }
}