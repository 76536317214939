import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home';
import { LoginComponent } from './login';
import {DashboardComponent} from './dashboard/dashboard.component';
import {PrestazioniComponent} from './prestazioni/prestazioni.component';
import {PazientiComponent} from './pazienti/pazienti.component';
import {CorsiComponent} from './corsi/corsi.component';
import {IscrizioniComponent} from './iscrizioni/iscrizioni.component';
import {FattureComponent} from './fatture/fatture.component';
import {CalendarioComponent} from './calendario/calendario.component';
import { AuthGuard } from './_helpers';

const routes: Routes = [
    { 
        path: '', 
        component: HomeComponent, 
        canActivate: [AuthGuard],
        data: { access_level: [1,2,3,4,5,6,7,8,9,10] } ,
        children:[
            {
                path:'',
                component: DashboardComponent,
                canActivate: [AuthGuard],
                data:{access_level: [1,2,3,4,5,6,7,8,9,10]}
            },
            {
                path:'calendario',
                component: CalendarioComponent,
                canActivate: [AuthGuard],
                data:{access_level: [1,2,3,4,5,6,7,8,9,10]}
            },
            {
                path:'prestazioni',
                component: PrestazioniComponent,
                canActivate: [AuthGuard],
                data:{access_level: [1,2,3,4,5,6,7,8,9,10]}
            },
            {
                path:'pazienti',
                component: PazientiComponent,
                canActivate: [AuthGuard],
                data:{access_level: [1,2,3,4,5,6,7,8,9,10]}
            },
            {
                path:'corsi',
                component: CorsiComponent,
                canActivate: [AuthGuard],
                data:{access_level: [1,2,3,4,5,6,7,8,9,10]}
            },
            {
                path:'iscrizioni',
                component: IscrizioniComponent,
                canActivate: [AuthGuard],
                data:{access_level: [1,2,3,4,5,6,7,8,9,10]}
            },
            {
                path:'fatture',
                component: FattureComponent,
                canActivate: [AuthGuard],
                data:{access_level: [1,2,3,4,5,6,7,8,9,10]}
            }
        ]
    },
    { 
        path: 'login', 
        component: LoginComponent 
    },

    // otherwise redirect to home
    { path: '**', redirectTo: '' }
];

export const appRoutingModule = RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' });