import { Component, OnInit, ViewChild } from '@angular/core';
import {Fattura, Paziente, RigaFattura, Prestazioni, RESPONSE} from '../_models';
import { GlobalServices } from '../_services';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import moment from 'moment';

@Component({
  selector: 'app-fatture',
  templateUrl: './fatture.component.html',
  styleUrls: ['./fatture.component.scss']
})
export class FattureComponent implements OnInit {
  fattura= new Fattura();
  fatture= new Array<Fattura>();
  paziente: Paziente;
  pazienti: Array<Paziente>;
  myControl = new FormControl();
  filteredOptions: Observable<Paziente[]>;
  brush=false;
  anni= new Array<number>();
  anno_selezionato;
  prest= new Array<Prestazioni>();
  elencoFatture = new MatTableDataSource(this.fatture);
  data_min;
  data_min_1;
  data_inizio;
  data_fine;
  export_fatture;
  export_fatture_filtered;
  tasse=0;
  filtro_pagamento="Tutti";
  modifica=false;
  selectedTab=0;
  fattura_disabilitata=false;
  displayedColumns: string[] = ['tipo_fattura','data_fattura', 'numero_fattura', 'paziente', 'totale_netto','tipo_pagamento','azioni'];
  @ViewChild(MatSort) sort: MatSort;
  totale_fatture: any;



  constructor(private service: GlobalServices, private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.CaricaPazienti();
    let today= new Date();
    let today_anno=today.getFullYear();
    let startYear=2019;
    for(let i=startYear;i<=today_anno; i++ )
    {
      this.anni.push(i);
    }
    this.anni=this.anni.sort((a,b)=>b-a);
    this.anno_selezionato=this.anni[0];
    this.fattura.tipo_pagamento="Contanti";
    this.NumeroFattura();
    this.CaricaPrestazioni();
    this.CaricaFatture();
    this.service.CaricaImpostazioni();
  }

  Modifica(element){
    this.selectedTab=0;
    this.fattura=element;
    this.myControl.setValue(element.paziente.cognome+' '+element.paziente.nome+' '+element.paziente.cf+' - '+element.paziente.indirizzo+', '+element.paziente.citta);
    this.myControl.disable();
    this.brush=true;
    this.modifica=true;
  }

  CaricaFatture(){
    this.service.get_function(this.service.apiFattura+this.anno_selezionato).subscribe(
      (risultati: Array<any>)=>
      {
        risultati.forEach(element=>{
          element.paziente=JSON.parse(element.paziente);
          if(element.righe) element.righe=JSON.parse(element.righe);
        })
        //CARICA I DATI NELLA TABELLA
        this.elencoFatture=new MatTableDataSource(risultati);
        setTimeout(()=>{this.elencoFatture.sort=this.sort;});
        this.export_fatture=risultati;
        this.export_fatture_filtered=risultati;
        this.data_fine=risultati[0].data_fattura;
        this.data_inizio=risultati[risultati.length-1].data_fattura;
        this.data_min_1=this.data_inizio;
        this.CalcolaTotale(this.export_fatture_filtered);
      }
    )
  }

  GeneraExportFatture(fatt){
    this.service.GeneraExportFatture(fatt, this.data_inizio,this.data_fine)
  }

  FiltraFatture(){
    this.export_fatture_filtered=this.export_fatture.filter(element=>{
      return element.data_fattura>=this.data_inizio && element.data_fattura<=this.data_fine
    });
    if(this.filtro_pagamento!="Tutti") this.export_fatture_filtered=this.export_fatture_filtered.filter(element=>{
      return element.tipo_pagamento==this.filtro_pagamento
    })
    this.CalcolaTotale(this.export_fatture_filtered);
  }

  CalcolaTotale(fatture){
    this.totale_fatture=0;
    fatture.forEach(element=>{
      this.totale_fatture+=parseInt(element.totale_netto);
    })
  }

  CaricaPrestazioni()
  {
    this.service.get_function(this.service.apiPrestazioni).subscribe(
      (prestazioni: Prestazioni[])=>
      {
        this.prest=prestazioni;
      }
    )
  }

  SetPrestazione(option,i){
    this.fattura.righe[i].descrizione=option.descrizione;
    this.fattura.righe[i].descrizione_fattura=option.descrizione_fattura;
    this.fattura.righe[i].prezzo=option.prezzo;
    this.AggiornaTotale();
  }

  PushRiga(){
    this.fattura.righe.push({'descrizione':'','descrizione_fattura':'','prezzo':0,'quantita':1});
  }

  AggiornaTotale(){
   
    this.fattura.totale_lordo=0;
    this.fattura.bollo=0;
    this.fattura.righe.forEach(riga=>{
      this.fattura.totale_lordo+=(riga.prezzo*riga.quantita);
    });
    this.fattura.tasse=this.fattura.totale_lordo*this.tasse;
    if((this.fattura.tasse+this.fattura.totale_lordo)>=77.47) this.fattura.bollo=2;
    this.fattura.totale_netto=this.fattura.totale_lordo+this.fattura.tasse+this.fattura.bollo;
  }

  SpliceRiga(index){
    this.fattura.righe.splice(index, 1);
    this.AggiornaTotale();
  }

  NumeroFattura(){
    this.CaricaFatture();
    this.service.get_function(this.service.apiNumeroFattura+this.fattura.tipo_fattura+'/'+this.anno_selezionato).subscribe(
      (risultato: any)=>
      {
        this.fattura.numero_fattura=parseInt(risultato.max)+1
        this.data_min=risultato.data;
      }
    )
  }

  CaricaPazienti()
  {
      this.service.get_function(this.service.apiPazienti).subscribe(
      (pazienti: Paziente[])=>
      {
        this.pazienti=pazienti;
        this.filteredOptions = this.myControl.valueChanges.pipe(
          startWith(''),
          map(value => this._filter(value))
        );
      }
    )
  }

  SetPaziente(option: Paziente){
    this.fattura.paziente=option;
    this.myControl.disable();
    this.brush=true;
  }

  Sblocca(){
    this.brush=false;
    this.fattura.paziente= new Paziente;
    this.myControl.setValue("");
    this.myControl.enable();
  }

  Salva()
  {
    this.fattura_disabilitata=true;
    if(this.fattura.totale_netto>0 && this.fattura.paziente.id_paziente)
    this.service.post_function(JSON.stringify(this.fattura), this.service.apiFattura).subscribe(
      (data: RESPONSE)=> {
        if(data.data='OK')
        this.fattura.numero_fattura=data.dati;
        this._snackBar.open('Operazione eseguita correttamente', 'OK', {
          duration: 3000,
          verticalPosition: 'top'
        });
        this.service.GeneraFattura(this.fattura,1);
        this.NumeroFattura();
        this.Sblocca();
        this.modifica=false;
        this.selectedTab=0;
        this.fattura_disabilitata=false;
      }
    )
    else
    {
      this._snackBar.open('Manca qualche dato, ricontrolla', 'Torna', {
        duration: 5000,
        verticalPosition: 'bottom'
      });
      this.fattura_disabilitata=false;
    }
  }

  StampaFattura(element,print){
    if(print==2){
      let data= this.service.GeneraFattura(element,print);
      this._snackBar.open('Operazione eseguita correttamente', 'OK', {
        duration: 3000,
        verticalPosition: 'top'
      });
    }
    else this.service.GeneraFattura(element,print);
  }

  private _filter(value: string): Paziente[] {
    const filterValue = value.toLowerCase();

    return this.pazienti.filter(option => option.cognome.toLowerCase().indexOf(filterValue) === 0);
  }

}
