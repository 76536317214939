<mat-card >
    <mat-card-subtitle class="dashboard-subtitle">Calendario {{ viewDate | calendarDate:(view + 'ViewTitle'):'it' }}</mat-card-subtitle> 
    <mat-card-content>
        <!--CALENDARIO-->
        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="800px">
            <div fxFlex="50%">
              <div fxLayout="row">
                <div fxFlex="33%" fxFlexFill>
                    <button mat-raised-button
                    color="primary"
                    mwlCalendarPreviousView
                    [view]="view"
                    [(viewDate)]="viewDate"
                    (viewDateChange)="closeOpenMonthViewDay()"
                    style="width:100%"
                    >
                    Precedente
                    </button>
                </div>
                <div fxFlex="33%" fxFlexFill>
                    <button mat-raised-button
                    color="accent"
                    mwlCalendarToday
                    [(viewDate)]="viewDate"
                    style="width:100%"
                    >
                    Oggi
                    </button>
                </div>
                <div fxFlex="33%" fxFlexFill>
                    <button mat-raised-button
                    color="primary"
                    mwlCalendarNextView
                    [view]="view"
                    [(viewDate)]="viewDate"
                    (viewDateChange)="closeOpenMonthViewDay()"
                    style="width:100%"
                    >
                    Prossimo
                    </button>
                </div>
              </div>
            </div>
            <div fxFlex="50%">
                <div fxLayout="row">
                  <div fxFlex="33%">
                      <button mat-raised-button
                      color="primary"
                      (click)="setView(CalendarView.Month)"
                      [class.active]="view === CalendarView.Month"
                      style="width:100%"
                      >
                      Mese
                      </button>
                  </div>
                  <div fxFlex="33%">
                      <button mat-raised-button
                      color="accent"
                      (click)="setView(CalendarView.Week)"
                      [class.active]="view === CalendarView.Week"
                      style="width:100%"
                      >
                      Settimana
                      </button>
                  </div>
                  <div fxFlex="33%">
                      <button mat-raised-button
                      color="primary"
                      (click)="setView(CalendarView.Day)"
                      [class.active]="view === CalendarView.Day"
                      style="width:100%"
                      >
                      Giorno
                      </button>
                  </div>
                </div>
              </div>
          </div>
          <br />
          <div [ngSwitch]="view">
            <mwl-calendar-month-view
              *ngSwitchCase="CalendarView.Month"
              [viewDate]="viewDate"
              [events]="events"
              [refresh]="refresh"
              [activeDayIsOpen]="activeDayIsOpen"
              (dayClicked)="dayClicked($event.day)"
              (eventClicked)="handleEvent('Clicked', $event.event)"
              (eventTimesChanged)="eventTimesChanged($event)"
              (beforeViewRender)="updateCalendarEvents($event)"
            >
            </mwl-calendar-month-view>
            <mwl-calendar-week-view
              *ngSwitchCase="CalendarView.Week"
              [viewDate]="viewDate"
              [events]="events"
              [refresh]="refresh"
              (eventClicked)="handleEvent('Clicked', $event.event)"
              (eventTimesChanged)="eventTimesChanged($event)"
              (beforeViewRender)="updateCalendarEvents($event)"
              [dayStartHour]="dayStartHour"
              [dayEndHour]="dayEndHour"
              [excludeDays]="excludeDays"
            >
            </mwl-calendar-week-view>
            <mwl-calendar-day-view
              *ngSwitchCase="CalendarView.Day"
              [viewDate]="viewDate"
              [events]="events"
              [refresh]="refresh"
              (eventClicked)="handleEvent('Clicked', $event.event)"
              (eventTimesChanged)="eventTimesChanged($event)"
              (beforeViewRender)="updateCalendarEvents($event)"
              [dayStartHour]="dayStartHour"
              [dayEndHour]="dayEndHour"
            >
            </mwl-calendar-day-view>
          </div>
          <!--NUOVO APPUNTAMENTO-->
          <mat-card>
              <mat-card-subtitle class="dashboard-subtitle" >Inserisci appuntamento</mat-card-subtitle>
              <mat-card-content>
                  <form [formGroup]="AppuntamentoForm" (ngSubmit)="SalvaAppuntamento()">
                  <div fxLayout="row" fxLayoutGap="5px" fxLayout.xs="column">
                      <div fxFlex="20%" fxFlexFill>
                        <mat-form-field class="example-full-width">
                            <mat-label>Paziente</mat-label>
                            <mat-select class="example-full-width" formControlName="id_paziente" required>
                            <mat-option *ngFor="let el of pazienti" [value]="el.id_paziente">{{el.cognome}} {{el.nome}} {{el.data_di_nascita | date:'dd/MM/yyyy'}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                      </div>
                      <div fxFlex="20%" fxFlexFill>
                        <!--PRESTAZIONE-->
                        <mat-form-field class="example-full-width">
                            <mat-label>Prestazione</mat-label>
                            <mat-select class="example-full-width" formControlName="id_prestazione" required>
                            <mat-option *ngFor="let el of elencoPrestazioni" [value]="el.id_prestazione">{{el.descrizione}} - durata: {{el.durata}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div fxFlex="15%">
                        <!--DATA-->
                        <mat-form-field fxFlexFill>
                            <mat-label>Data Appuntamento</mat-label>
                            <input type="date" matInput formControlName="data" required>
                        </mat-form-field>
                    </div>
                    <div fxFlex="15%">
                        <!--ORA-->
                        <mat-form-field fxFlexFill>
                            <mat-label>Ora</mat-label>
                            <input type="time" matInput formControlName="ora" required>
                        </mat-form-field>
                    </div>
                    <div fxFlex="20%">
                        <!--NOTE-->
                        <mat-form-field fxFlexFill>
                            <mat-label>Note</mat-label>
                            <input type="text" matInput formControlName="note">
                        </mat-form-field>
                    </div>
                    <div fxFlex="10%">
                        <!--SUBMIT-->
                        <button mat-raised-button color="primary" type="submit" [disabled]="!AppuntamentoForm.valid"><mat-icon>save</mat-icon></button>
                    </div>
                  </div>
                  </form>
              </mat-card-content>
          </mat-card>
    </mat-card-content>

</mat-card>
