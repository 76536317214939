<mat-card>
    <mat-card-title>Dashboard - {{data_now | date: 'EEEE dd MMMM yyyy' }}</mat-card-title><!-- - HH:mm:ss' :'+0100'-->
    <mat-card-content>
    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px" fxFlexFill>
        <div fxFlex="50%" id="box-1" fxFlexFill>
            <mat-card >
                <mat-card-subtitle class="dashboard-subtitle">Registra presenza</mat-card-subtitle> 
                <mat-card-content>
                    <mat-card>
                        <mat-card-title>Inserisci Codice Paziente</mat-card-title>
                        <mat-card-subtitle>Il sistema scalerà automaticamente la presenza del paziente dal corso a cui è iscritto</mat-card-subtitle>
                        <mat-card-content>
                           <div fxLayout="row" fxLayout.xs="column">
                                <div fxFlex="100%">
                                    <mat-form-field class="example-full-width">
                                        <mat-label>Codice Persona</mat-label>
                                        <input matInput [(ngModel)]="codice_persona" name="codice_persona" (ngModelChange)="RegistraPresenza()">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div fxLayout="row" *ngIf="rimane">
                                <div fxFlex="100%" class="hint-dashboard">
                                    Codice: {{codice_persona_temp}} - Rimangono solo: {{rimanenti}} sedute;
                                </div>
                            </div>
                            <div fxLayout="row" *ngIf="codice_non_trovato">
                                <div fxFlex="100%" class="error-dashboard">
                                    <mat-icon>warning</mat-icon>&nbsp;<b>ERRORE</b> - controllare i seguenti problemi:<br>
                                    <ul>
                                        <li>Codice errato;</li>
                                        <li>Il paziente ha esaurito le prestazioni del suo corso;</li>
                                        <li>Il paziente ha già registrato la sua presenza in questa data.</li>
                                    </ul>
                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </mat-card-content>

            </mat-card>
            <mat-card>
                <mat-card-subtitle class="dashboard-subtitle">Verifica Paziente</mat-card-subtitle> 
                <mat-card-content>
                    <mat-card>
                        <mat-card-subtitle>Inserisci un codice paziente per visualizzare le ultime presenze delle sue iscrizioni</mat-card-subtitle>
                        <mat-card-content>
                            <div fxLayout="row" fxLayout.xs="column">
                                <div fxFlex="70%">
                                    <mat-form-field class="example-full-width">
                                        <mat-label>Codice Persona</mat-label>
                                        <input matInput [(ngModel)]="codice_verifica" name="codice_verifica" (ngModelChange)="VerificaIscrizioni()">
                                    </mat-form-field>
                                </div>
                                <div fxFlex="30%" *ngIf="disabled">
                                    <i>Il codice deve essere lungo 10 caratteri</i>
                                </div>
                            </div>
                            <div fxLayout="row" *ngIf="verifica_iscrizioni">
                                <div fxFlex="100%">
                                <mat-card>
                                    <mat-card-subtitle>{{verifica_iscrizioni.paziente.cognome}} {{verifica_iscrizioni.paziente.nome}}</mat-card-subtitle>
                                    <mat-card-content>
                                        <div fxLayout="row" *ngFor="let iscrizione of verifica_iscrizioni.iscrizioni">
                                            <div fxFlex="100%">
                                                <b>{{iscrizione.descrizione}} - Inizio: {{iscrizione.data_inizio | date: 'dd/MM/yyyy'}} - Rimanenti: {{iscrizione.rimanenti}}</b>
                                                <p>{{iscrizione.presenze.length}} presenze:</p>
                                                <p *ngFor="let presenza of iscrizione.presenze; let i = index"><i>#{{i+1}} - {{presenza | date:'dd/MM/yyyy'}}</i></p>
                                            </div>
                                        </div>
                                    </mat-card-content>
                                </mat-card>
                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>

                </mat-card-content>
            </mat-card>
        </div>
        <div fxFlex="50%" id="box-2" fxFlexFill>
            <mat-card >
                <mat-card-subtitle class="dashboard-subtitle">Appunti Segreteria&nbsp;<span [matBadge]="appunti.length" matBadgeOverlap="false" *ngIf="appunti.length"></span></mat-card-subtitle>
                <mat-card-content>
                    <div fxLayout="row" fxLayout.xs="column">
                        <div fxFlex="50%" class="dashboard-notes">
                            <span *ngIf="!appunti.length" class="notes"><mat-icon style="color: green">new_releases</mat-icon><i><b>Nessun appunto da visualizzare</b></i></span>
                            <div fxLayout="row" *ngFor="let app of appunti" class="notes">
                                <div fxFlex="15%"><button mat-button (click)="EliminaAppunto(app.id_appunto)"><mat-icon style="color: red">delete</mat-icon></button></div>
                                <div fxFlex="85%">{{app.appunto}}</div>
                            </div>
                        </div>
                        <div fxFlex="50%">
                            <mat-card>
                                <mat-card-title>Inserisci un appunto</mat-card-title>
                                <mat-card-content>
                                    <p>
                                    <mat-form-field class="example-full-width">
                                        <mat-label>Richiamare, avvisare, ecc..</mat-label>
                                        <input matInput [(ngModel)]="appunto.appunto" name="appunto.appunto">
                                    </mat-form-field>
                                    </p>
                                    <p>
                                        <button mat-raised-button color="primary" (click)="InserisciAppunto()">Inserisci</button>
                                    </p>
                                </mat-card-content>
                            </mat-card>
                        </div>
                    </div>
                </mat-card-content> 
                
            </mat-card>
        </div>
    </div>
    <p><!-- Spazio vuoto--></p>
    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px" fxFlexFill>
        <div fxFlex="100%" id="box-3" fxFlexFill>
            <mat-card>
                <mat-card-title>Presenze Registrate Oggi</mat-card-title>
                <mat-card-content>
                    <div  *ngIf="presenzeOggi.length>0">
                        <div fxLayout="column">
                            <div fxFlex="100%" *ngFor="let p of presenzeOggi; let j=index">
                               {{j+1}}) Codice: {{p.codice}} | {{p.cognome}} {{p.nome}} | {{p.descrizione}} - {{p.ora_inizio}}
                            </div>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
    </mat-card-content>
</mat-card>
