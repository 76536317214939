<mat-card>
    <mat-card-title>Gestione Iscrizioni</mat-card-title>
    <mat-card-subtitle>Visualizzare le iscrizioni attive o scadute ed aggiungerne di nuove</mat-card-subtitle>
    <mat-card-content>
        <mat-card>
            <mat-card-subtitle>Aggiungi nuova iscrizione</mat-card-subtitle>
            <mat-card-content>
                <form [formGroup]="IscrizioniForm" (ngSubmit)="Salva()">
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px">
                        <div fxFlex="50%">
                            <mat-form-field class="example-full-width">
                                <mat-label>Paziente</mat-label>
                                <mat-select [(ngModel)]="iscrizione.id_paziente" name="iscrizione.id_paziente" formControlName="paziente" required>
                                <mat-option *ngFor="let el of pazienti" [value]="el.id_paziente">{{el.cognome}} {{el.nome}} {{el.data_di_nascita | date: 'dd/MM/yyyy'}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div fxFlex="50%">
                            <mat-form-field class="example-full-width">
                                <mat-label>Corso</mat-label>
                                <mat-select [(ngModel)]="iscrizione.id_corso" name="iscrizione.id_corso" formControlName="corso" required>
                                <mat-option *ngFor="let el of corsi" [value]="el.id_corso">{{el.descrizione}} - {{el.ora_inizio}} / {{el.ora_fine}} - {{get_giorno(el.giorno1)}}, {{get_giorno(el.giorno2)}}, {{get_giorno(el.giorno3)}} - Posti rimanenti: {{el.numero_partecipanti}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px">
                        <div fxFlex="50%">
                            <mat-form-field class="example-full-width">
                                <mat-label>Numero di sedute</mat-label>
                                <input type="number" min='0' matInput [(ngModel)]="iscrizione.rimanenti" name="iscrizione.rimanenti" formControlName="rimanenti" required>
                            </mat-form-field>
                        </div>
                        <div fxFlex="50%">
                            <mat-form-field class="example-full-width">
                                <mat-label>Data di Inizio</mat-label>
                                <input type="date" matInput [(ngModel)]="iscrizione.data_inizio" name="iscrizione.data_inizio" formControlName="data_inizio" required>
                            </mat-form-field>
                        </div>
                    </div>
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px">
                        <div fxFlex="100%">
                            <button fxFlexFill mat-raised-button color="primary" type="submit" [disabled]="!IscrizioniForm.valid">Salva</button>
                        </div>
                    </div>
                </form>
            </mat-card-content>
        </mat-card>
        <p></p>
        <div fxLayoutt="row" fxLayout.xs="column">
            <div fxFlex="70%">
                <mat-form-field class="example-full-width">
                    <mat-label>Filtra</mat-label>
                    <input matInput (keyup)="applyFilter($event)" placeholder="Filtro per cognome, nome, giorni ecc." #input>
                  </mat-form-field>
            </div>
        </div>
        <table mat-table [dataSource]="elencoIscrizioni" matSort>

            <ng-container matColumnDef="cognome">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Paziente </th>
              <td mat-cell *matCellDef="let element"> {{element.cognome}}</td>
            </ng-container>

            <ng-container matColumnDef="nome">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">{{element.nome}} - {{element.data_di_nascita | date: 'dd/MM/yyyy'}} </td>
              </ng-container>

              <ng-container matColumnDef="descrizione">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Corso </th>
                <td mat-cell *matCellDef="let element"> {{element.descrizione}}</td>
              </ng-container>
          
            <ng-container matColumnDef="corso">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Dettagli Corso </th>
              <td mat-cell *matCellDef="let element">{{element.ora_inizio}} / {{element.ora_fine}} - {{element.giorno1}}, {{element.giorno2}}, {{element.giorno3}} </td>
            </ng-container>
          
            <ng-container matColumnDef="data_inizio">
              <th mat-header-cell *matHeaderCellDef> Data di Inizio </th>
              <td mat-cell *matCellDef="let element"> {{element.data_inizio | date: 'dd/MM/yyyy'}} </td>
            </ng-container>

            <ng-container matColumnDef="rimanenti">
                <th mat-header-cell *matHeaderCellDef mat-sort-header > Sedute Rimanenti </th>
                <td mat-cell *matCellDef="let element"> {{element.rimanenti}} </td>
            </ng-container>

            <ng-container matColumnDef="attivo">
                <th mat-header-cell *matHeaderCellDef mat-sort-header > Attivo? </th>
                <td mat-cell *matCellDef="let element"> <span *ngIf="element.attivo==1">SI</span><span *ngIf="element.attivo==0">NO</span></td>
            </ng-container>

            <ng-container matColumnDef="giorno3">
                <th mat-header-cell *matHeaderCellDef mat-sort-header > Giorno 3 </th>
                <td mat-cell *matCellDef="let element"> {{get_giorno(element.giorno3)}} </td>
            </ng-container>

            <ng-container matColumnDef="numero_partecipanti">
              <th mat-header-cell *matHeaderCellDef > Max partecipanti </th>
              <td mat-cell *matCellDef="let element"> {{element.numero_partecipanti}} </td>
            </ng-container>

            <ng-container matColumnDef="azioni">
                <th mat-header-cell *matHeaderCellDef > Azioni </th>
                <td mat-cell *matCellDef="let element">
                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                        <mat-icon>more_vert</mat-icon>
                    </button> 
                    <mat-menu #menu="matMenu">
                        <button *ngIf="element.attivo==1" (click)="Elimina(element)" mat-menu-item><mat-icon mat-list-icon style="color: red">sync_disabled</mat-icon>Disattiva</button>
                        <button *ngIf="element.attivo==0" (click)="RinnovaIscrizione(element)" mat-menu-item><mat-icon mat-list-icon style="color:green">update</mat-icon>Rinnova</button>
                    </mat-menu>
                </td>
              </ng-container>
          
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
    </mat-card-content>
</mat-card>
