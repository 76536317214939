
<h1>{{data.evento.title}}</h1>
<h5>{{data.evento.start | date: 'EEEE dd MMMM yyyy - HH:mm' }}</h5>
<div fxLayout="column" fxFlexFill fxLayoutGap="10px">
    <div fxFlex="100%">
        Nr. di telefono: {{data.evento.meta.telefono}}
    </div>
    <div fxFlex="100%">
        Email: {{data.evento.meta.email}}
    </div>
    <div fxFlex="100%">
        Note: {{data.evento.meta.note}}
    </div>
</div>
