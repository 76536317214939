import { Component, OnInit, ViewChild, Inject} from '@angular/core';
import {Paziente, Corsi, Iscrizioni, Giorni, RESPONSE, Iscrizioni_VIEW} from '../_models';
import {GlobalServices} from '../_services';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {FormControl, FormGroup} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface DialogData {
  iscrizione: Iscrizioni_VIEW;
}

@Component({
  selector: 'app-iscrizioni',
  templateUrl: './iscrizioni.component.html',
  styleUrls: ['./iscrizioni.component.scss']
})
export class IscrizioniComponent implements OnInit {

  IscrizioniForm=new FormGroup({
    paziente: new FormControl(),
    corso: new FormControl(),
    rimanenti: new FormControl(),
    data_inizio: new FormControl(),
  });
  giorni: Giorni[];
  iscrizione= new Iscrizioni();
  pazienti:Paziente[];
  corsi: Corsi[];
  iscrizioni_view= new Array<Iscrizioni_VIEW>();
  displayedColumns: string[] = ['cognome','nome', 'descrizione', 'corso', 'data_inizio','rimanenti', 'attivo','azioni'];
  elencoIscrizioni = new MatTableDataSource(this.iscrizioni_view);
  @ViewChild(MatSort) sort: MatSort;

  constructor(private service: GlobalServices, private _snackBar: MatSnackBar, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.iscrizione.rimanenti=8;
    this.service.get_function(this.service.apiGiorni).subscribe(
      (giorni: Giorni[])=>
      {
        this.giorni=giorni;
        this.CaricaIscrizioni();
        this.service.get_function(this.service.apiPazienti).subscribe(
          (paz: Paziente[])=>{this.pazienti=paz}
        )
        this.service.get_function(this.service.apiCorsi+'iscrizioni').subscribe(
          (cor: Corsi[])=>{this.corsi=cor}
        )
      }
    )
  }

  Salva()
  {
    this.service.post_function(JSON.stringify(this.iscrizione), this.service.apiIscrizioni).subscribe(
      (data: RESPONSE)=> {
        if(data.data='OK')
        this._snackBar.open('Operazione eseguita correttamente', 'OK', {
          duration: 3000,
          verticalPosition: 'top'
        });
        this.iscrizione=new Iscrizioni();
        this.CaricaIscrizioni();
      }
    )
  }

  Elimina(element){
    const dialogRef = this.dialog.open(DialogOverviewIscrizioniDialog, {
      width: '250px',
      data: {iscrizione: element}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) 
      this.service.delete_function(this.service.apiIscrizioni+element.id_iscrizione).subscribe(
        (data: RESPONSE)=> {
          if(data.data='OK')
          this._snackBar.open('Operazione eseguita correttamente', 'OK', {
            duration: 3000,
            verticalPosition: 'top'
          });
          this.CaricaIscrizioni();
        }
      )
    });
  }

applyFilter(event: Event){  
  const filterValue = (event.target as HTMLInputElement).value;
  this.elencoIscrizioni.filter = filterValue.trim().toLowerCase();
}

  CaricaIscrizioni(){
    this.service.get_function(this.service.apiIscrizioni).subscribe(
      (iscrizioni: Iscrizioni_VIEW[])=>
      {
        this.elencoIscrizioni=new MatTableDataSource(iscrizioni);
        setTimeout(()=>{this.elencoIscrizioni.sort=this.sort;});
      }
    )
  }

  RinnovaIscrizione(iscr: Iscrizioni_VIEW){
    this.iscrizione.data_inizio=new Date();
    this.iscrizione.id_paziente=iscr.id_paziente;
    this.iscrizione.id_corso=iscr.id_corso;
    this.iscrizione.rimanenti=8;
    this.Salva();
  }

  get_giorno(x){
    let giorno= new Giorni();
    giorno=this.giorni.find(function(element){return element.numero==x});
    return giorno.descrizione;
  }

}

@Component({
  selector: 'dialog-overview-example-dialog',
  templateUrl: 'dialog-overview-example-dialog.html',
})
export class DialogOverviewIscrizioniDialog {

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewIscrizioniDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

}
