<mat-card>
    <mat-card-title>FATTURAZIONE</mat-card-title>
    <mat-card-subtitle>Gestisci le tue fatture da questa sezione</mat-card-subtitle>
    <mat-card-content>
        <div fxLayout="row">
            <div fxFlex="10%"><p>Seleziona anno: </p></div>
            <div fxFlex="90%">
                <mat-form-field>
                    <mat-select [(ngModel)]="anno_selezionato" name="anno_selezionato" (selectionChange)="NumeroFattura()" [disabled]="modifica">
                        <mat-option *ngFor="let anno of anni" [value]="anno">{{anno}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <mat-tab-group color="accent" backgroundColor="accent" [(selectedIndex)]="selectedTab">
        <!--INSERIMENTO NUOVA FATTURA-->
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon style="color:white" >add</mat-icon>
                Nuova Fattura
              </ng-template>
        <mat-card>
            <mat-card-content>
                <div fxLayout="row" fxLayout.xs="column" fxFlexFill fxLayoutGap="10px">
                    
                    <div fxFlex="10%" fxFlexFill>
                        <p><b>Numero Fattura: #{{fattura.numero_fattura}}</b></p>
                    </div>
                    <div fxFlex="10%" fxFlexFill>
                        <mat-form-field class="example-full-width">
                            <mat-label>Tipo Documento</mat-label>
                            <mat-select [(ngModel)]="fattura.tipo_fattura" name="fattura.tipo_fattura" (selectionChange)="NumeroFattura()" [disabled]="modifica">
                                <mat-option value="Fattura">Fattura</mat-option>
                                <mat-option value="NotaCredito">Nota di Credito</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div fxFlex="10%" fxFlexFill >
                        <mat-form-field >
                            <mat-label>Data Documento</mat-label>
                            <input type="date" matInput [(ngModel)]="fattura.data_fattura" name="fattura.data_fattura" [min]="data_min" onkeydown="return false" [disabled]="modifica">
                        </mat-form-field>
                    </div>
                    <div fxFlex="10%" fxFlexFill>
                        <mat-form-field class="example-full-width">
                            <mat-label>Tipo Pagamento</mat-label>
                            <mat-select [(ngModel)]="fattura.tipo_pagamento" name="fattura.tipo_pagamento" >
                                <mat-option value="Contanti">Contanti</mat-option>
                                <mat-option value="POS">POS</mat-option>
                                <mat-option value="Assegno">Assegno</mat-option>
                                <mat-option value="Bonifico">Bonifico</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div fxFlex="60%" fxFlexFill>
                        <form class="example-form">
                        <mat-form-field class="example-full-width">
                            <mat-icon *ngIf="brush" (click)="Sblocca()" matPrefix color="accent">brush</mat-icon>
                            <input type="text"
                                   placeholder="Ricerca Paziente per cognome"
                                   matInput
                                   [formControl]="myControl"
                                   [matAutocomplete]="auto">
                            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                              <mat-option *ngFor="let option of filteredOptions | async" [value]="option.cognome +' '+ option.nome + ' '+ option.cf + ' - ' + option.indirizzo + ', '+option.citta" (click)="SetPaziente(option)">
                                {{option.cognome}} {{option.nome}} - {{option.data_di_nascita | date:"dd/MM/yyyy"}}, {{option.cf}} - {{option.indirizzo}}, {{option.citta}}
                              </mat-option>
                            </mat-autocomplete>
                          </mat-form-field>
                        </form>
                    </div>
                </div>
                <mat-card *ngFor="let riga of fattura.righe; let i=index">
                    <mat-card-subtitle class="subtitle-fattura">Riga n°{{i+1}} <button *ngIf="fattura.righe.length>1" mat-icon-button (click)="SpliceRiga(i)"><mat-icon style="color: red">remove_circle_outline</mat-icon></button></mat-card-subtitle>
                    <mat-card-content>
                        <div fxLayout="row" fxLayout.xs="column" fxFlexFill fxLayoutGap="10px" >
                            <div fxFlex="20%">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Seleziona prestazione preimpostata</mat-label>
                                    <mat-select (selectionChange)="SetPrestazione($event.value,i)">
                                        <mat-option *ngFor="let prestazione of prest" [value]="prestazione">{{prestazione.descrizione}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        
                            <div fxFlex="60%">
                                <div fxLayout="column" fxFlexFill fxLayoutGap="10px" >
                                    <div fxFlex="100%">
                                        <mat-form-field class="example-full-width">
                                            <mat-label>Prestazione</mat-label>
                                            <input matInput [(ngModel)]="fattura.righe[i].descrizione" name="fattura.righe.descrizione+{{i}}">
                                        </mat-form-field>
                                    </div>
                                    <div fxFlex="100%">
                                        <mat-form-field class="example-full-width">
                                            <mat-label>Ulteriore riga di descrizione</mat-label>
                                            <input matInput [(ngModel)]="fattura.righe[i].descrizione_fattura" name="fattura.righe.descrizione_fattura+{{i}}">
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div fxFlex="10%">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Prezzo</mat-label>
                                    <span matPrefix>€ &nbsp;</span>
                                    <input type="number" min="0" matInput [(ngModel)]="fattura.righe[i].prezzo" name="fattura.righe.prezzo+{{i}}" (ngModelChange)="AggiornaTotale()">
                                </mat-form-field>
                            </div>
                            <div fxFlex="10%">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Quantità</mat-label>
                                    <input type="number" min="1" matInput [(ngModel)]="fattura.righe[i].quantita" name="fattura.righe.quantita+{{i}}" (ngModelChange)="AggiornaTotale()">
                                </mat-form-field>
                            </div>
                    </div>
                    </mat-card-content>
                </mat-card>
                <div fxLayout="row">
                    <div fxFlex="100%">
                    <button mat-raised-button color="primary" aria-label="Aggiungi prestazione" matTooltip="Aggiungi un'altra prestazione" (click)="PushRiga()">
                        <mat-icon style="color: white; font-weight: 700;">add</mat-icon>
                      </button>
                    </div>
                </div>
                <div fxLayout="row">
                    <div fxFlex="100%">
                        <mat-card>
                            <mat-card-content class="last-row">
                                <div fxLayout="column">
                                    <div fxFlex="100%" >
                                        Totale Prestazioni: {{fattura.totale_lordo}} €
                                    </div>
                                    <div fxFlex="100%" *ngIf="fattura.tasse>0">
                                        Tasse: {{fattura.tasse}} €
                                    </div>
                                    <div fxFlex="100%" *ngIf="fattura.bollo>0">
                                        Marca da Bollo: {{fattura.bollo}} €
                                    </div>
                                    <div fxFlex="100%">
                                        Totale da pagare: {{fattura.totale_netto}} €
                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="end baseline">
                    <div>
                        <button mat-raised-button color="primary" aria-label="Aggiungi prestazione" matTooltip="Salva la fattura" (click)="Salva()" [disabled]="fattura_disabilitata">
                            <mat-icon style="color: white; font-weight: 700;">save</mat-icon>
                          </button>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </mat-tab>
        <!--ELENCO FATTURE-->
    <mat-tab label="Elenco Fatture"> 
        <table mat-table [dataSource]="elencoFatture" matSort>

            <ng-container matColumnDef="tipo_fattura">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo Documento </th>
              <td mat-cell *matCellDef="let element"> {{element.tipo_fattura}}</td>
            </ng-container>

            <ng-container matColumnDef="data_fattura">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Data </th>
                <td mat-cell *matCellDef="let element">{{element.data_fattura | date: 'dd/MM/yyyy'}}</td>
              </ng-container>

              <ng-container matColumnDef="numero_fattura">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Numero Documento </th>
                <td mat-cell *matCellDef="let element">{{element.numero_fattura}}</td>
              </ng-container>

            <ng-container matColumnDef="paziente">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">{{element.paziente.cognome}} {{element.paziente.nome}} - {{element.paziente.data_di_nascita | date: 'dd/MM/yyyy'}} </td>
              </ng-container>

              <ng-container matColumnDef="totale_netto">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Totale Fattura </th>
                <td mat-cell *matCellDef="let element"> € {{element.totale_netto}}</td>
              </ng-container>
          
            <ng-container matColumnDef="tipo_pagamento">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Madalità di pagamento </th>
              <td mat-cell *matCellDef="let element">{{element.tipo_pagamento}} </td>
            </ng-container>

            <ng-container matColumnDef="azioni">
                <th mat-header-cell *matHeaderCellDef > Azioni </th>
                <td mat-cell *matCellDef="let element">
                        <button (click)="Modifica(element)" mat-icon-button><mat-icon style="color:yellowgreen">edit</mat-icon></button>
                        <button (click)="StampaFattura(element,1)" mat-icon-button><mat-icon style="color:coral">print</mat-icon></button>
                        <button (click)="StampaFattura(element,0)" mat-icon-button><mat-icon style="color: red">picture_as_pdf</mat-icon></button>
                        <button (click)="StampaFattura(element,2)" mat-icon-button><mat-icon style="color: blue">email</mat-icon></button>
                </td>
              </ng-container>
          
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>    
    </mat-tab>
    <mat-tab label="Esporta Fatture">
        <div fxLayout="row" fxLayout.xs="column">
            <div fxFlex="20%">
                <input type="date" [min]="data_min_1" [(ngModel)]="data_inizio" name="data_inizio" (change)="FiltraFatture()">
            </div>
            <div fxFlex="20%">
                <input type="date" [min]="data_inizio" [(ngModel)]="data_fine" name="data_fine" (change)="FiltraFatture()">
            </div>
            <div fxFlex="20%">
                Modalità di Pagamento 
                <mat-select [(ngModel)]="filtro_pagamento" name="filtro_pagamento" (selectionChange)="FiltraFatture()">
                    <mat-option value="Tutti">Tutti</mat-option>
                    <mat-option value="Contanti">Contanti</mat-option>
                    <mat-option value="POS">POS</mat-option>
                    <mat-option value="Bonifico">Bonifico</mat-option>
                    <mat-option value="Assegno">Assegno</mat-option>
                </mat-select>
            </div>
            <div fxFlex="20%">
                <button (click)="GeneraExportFatture(export_fatture_filtered)">Genera</button>
            </div>
        </div>
        <div fxLayout="row">
            <div fxFlex="100%">
                <table class="tabella">
                    <tr>
                        <th>Tipo Documento</th>
                        <th>Numero Documento</th>
                        <th>Data Documento</th>
                        <th>Totale Documento</th>
                        <th>Modalità di pagamento</th>
                        <th>Paziente</th>
                        <th>Dettaglio</th>
                    </tr>
                    <tr *ngFor="let fattura of export_fatture_filtered">
                        <td>{{fattura.tipo_fattura}}</td>
                        <td>{{fattura.numero_fattura}}</td>
                        <td>{{fattura.data_fattura | date:'dd/MM/yyyy'}}</td>
                        <td>€ {{fattura.totale_netto}}</td>
                        <td>{{fattura.tipo_pagamento}}</td>
                        <td>{{fattura.paziente.cognome}} {{fattura.paziente.nome}} - {{fattura.paziente.cf}} - {{fattura.paziente.indirizzo}}, {{fattura.paziente.citta}}</td>
                        <td><p *ngFor="let riga of fattura.righe">{{riga.quantita}} x {{riga.descrizione}}, {{riga.prezzo}}</p></td>
                    </tr>
                    <tr>
                        <th colspan="3">
                            TOTALE
                        </th>
                        <th>
                            € {{totale_fatture}}
                        </th>
                    </tr>
                </table>
            </div>
        </div>
    </mat-tab>
    </mat-tab-group>
    </mat-card-content>
</mat-card>
