<mat-grid-list [cols]="breakpoint" rowHeight="1:1" (window:resize)="onResize($event)">
<mat-grid-tile></mat-grid-tile>
<mat-grid-tile>
    <mat-card>
    <mat-card-title>LOGIN</mat-card-title>
    <mat-card-content>
            <form name="loginForm" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                <mat-form-field appearance="outline">
                    <mat-label>Username</mat-label>
                    <input matInput type="text" formControlName="username" name="username">
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Password</mat-label>
                    <input matInput type="password" formControlName="password" name="password">
                </mat-form-field>
                <div>
                <button mat-raised-button color="primary" [disabled]="loading">Login</button>
                </div>
                <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
            </form>
        </mat-card-content>
    </mat-card>
</mat-grid-tile>
<mat-grid-tile></mat-grid-tile>
</mat-grid-list>