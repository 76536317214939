export class User {
    id: number;
    username: string;
    password: string;
    access_level: number;
    e_mail: string;
}

export class Paziente{
    id_paziente;
    cognome;
    nome;
    data_di_nascita;
    cf;
    email;
    telefono;
    indirizzo;
    citta;
    codice;
    preferenza_com

    constructor(){
        this.preferenza_com='email';
    }
}