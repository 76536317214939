import { Component, OnInit, ChangeDetectionStrategy,ChangeDetectorRef} from '@angular/core';
import {Appunto, RESPONSE, Codici, Verifica_Iscrizioni, Paziente, Prestazioni, PresenzeOGGI} from '../_models';
import {MatDialog} from '@angular/material/dialog';
import{GlobalServices} from '../_services';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Howl, Howler} from 'howler';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit {

  //altre variabili
  data_now=new Date();
  appunto = new Appunto();
  appunti=new Array<Appunto>();
  codice_persona: string;
  codice_persona_temp:string;
  codice_verifica: string;
  disabled=true;
  elenco_codici_attivi: Array<Codici>;
  codice_non_trovato;
  verifica_iscrizioni: Verifica_Iscrizioni;
  rimanenti=0;
  rimane=false;
  giorni;
  elencoCorsi;
  presenzeOggi=new Array<PresenzeOGGI>();

  brush=false;

  pazienti: Paziente[];
  elencoPrestazioni: Prestazioni[];

  soundOK = new Howl({
    src: ['https://gestionale.fisiokineticalorusso.it/res/OK.mp3']
  });
  soundERROR = new Howl({
    src: ['https://gestionale.fisiokineticalorusso.it/res/ERROR.mp3']
  });

  constructor(private service: GlobalServices, private _snackBar: MatSnackBar,public dialog: MatDialog ) {  }

  ngOnInit() {
    
    this.RefreshDate();
    this.CaricaAppunti();
    this.CaricaCodici();
    this.CaricaPazienti();
    this.CaricaPresenzeOGGI();
  }

  CaricaPresenzeOGGI(){
    this.service.get_function(this.service.apiPresenzeOGGI).subscribe(
      (presenze: PresenzeOGGI[])=>
      {
        this.presenzeOggi=presenze;
      }
    )
  }

  CaricaPazienti()
  {
      this.service.get_function(this.service.apiPazienti).subscribe(
      (pazienti: Paziente[])=>
      {
        this.pazienti=pazienti;
      }
    )
  }

  CaricaCodici(){
    this.service.get_function(this.service.apiCodici).subscribe(
      (response: Codici[])=>
      {
        this.elenco_codici_attivi=response;
      }
    )
  }

  CaricaAppunti()
  {
    this.service.get_function(this.service.apiAppunti).subscribe(
      (response: Appunto[])=>
      {
        this.appunti=response;
      }
    )
  }

  EliminaAppunto(element){
    this.service.delete_function(this.service.apiAppunti+element).subscribe(
      (data: RESPONSE)=> {
        if(data.data='OK')
        this._snackBar.open('Operazione eseguita correttamente', 'OK', {
          duration: 3000,
          verticalPosition: 'top'
        });
        this.appunto=new Appunto();
        this.CaricaAppunti();
      }
    )
  }

  VerificaIscrizioni(){
    if(this.codice_verifica.length==10)
    {
      this.disabled=false;
      this.service.get_function(this.service.apiVerifica+this.codice_verifica).subscribe(
        (response: Verifica_Iscrizioni)=>
        {
          this.verifica_iscrizioni=response;
        }
      )

    }
    else{
      this.verifica_iscrizioni= new Verifica_Iscrizioni();
    }
  }

  RegistraPresenza(){

    if(this.codice_persona.length==10)
    {
      this.disabled=false;
      
      // CODICE PERSONA TROVATO TRA I CODICI ATTIVI
      if(this.elenco_codici_attivi.filter(element=>element.codice==this.codice_persona).length)
      {
        let temp=this.elenco_codici_attivi.filter(element=>element.codice==this.codice_persona)[0];
        this.elenco_codici_attivi=this.elenco_codici_attivi.filter(element=>element.codice!=this.codice_persona);
        this.service.post_function(JSON.stringify(temp), this.service.apiPresenza).subscribe(
          (data: RESPONSE)=> {
            if(data.data='OK')
            {
                  this._snackBar.open('Operazione eseguita correttamente', 'OK', {
                    duration: 3000,
                    verticalPosition: 'top'
                  });
                  this.rimanenti=data.dati;
                  this.rimane=true;
                  this.codice_persona_temp=this.codice_persona;
                  this.codice_persona="";
                  this.CaricaPresenzeOGGI();
            }
            else this.elenco_codici_attivi.push(temp);
            
          }
        )
      }
      // CODICE PERSONA NON TROVATO TRA I CODICI ATTIVI
      else 
      {
        this.codice_non_trovato=true;
        this.soundERROR.play();
      }
    }
    else
    {
      this.codice_non_trovato=false
      this.disabled=true;
    }
  }

  InserisciAppunto(){
    this.service.post_function(JSON.stringify(this.appunto), this.service.apiAppunti).subscribe(
      (data: RESPONSE)=> {
        if(data.data='OK')
        this._snackBar.open('Operazione eseguita correttamente', 'OK', {
          duration: 3000,
          verticalPosition: 'top'
        });
        this.appunto=new Appunto();
        this.CaricaAppunti();
      }
    )
  }

  RefreshDate(){
    this.data_now= new Date();
    //setTimeout(()=>{this.RefreshDate()}, 1000);
  }


}




