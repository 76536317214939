export class Fattura{
    id_fattura;
    data_fattura;
    numero_fattura: number;
    tipo_fattura;
    tipo_pagamento;
    totale_netto;
    bollo;
    righe=new Array<RigaFattura>();
    paziente;
    tasse;
    totale_lordo;

    constructor(){
        let riga: RigaFattura={'descrizione':'','descrizione_fattura':'','prezzo':0,'quantita':1};
        this.tipo_fattura="Fattura";
        this.righe.push(riga);
        let today=new Date().toISOString().substr(0, 10);
        this.data_fattura=today;//.getFullYear()+'-'+today.getMonth()+'-'+today.getDate();
        this.totale_lordo=0;
        this.totale_netto=0;
        this.tasse=0;
        this.bollo=0;
    }
}

export class RigaFattura{
    descrizione;
    descrizione_fattura;
    prezzo;
    quantita;
}

export class ImpostazioniFattura{
    tasse;
    img;
    iban;
    regime_fiscale;
}