import { Corsi } from './corsi';
import { Paziente } from './user';

export * from './user';
export * from './prestazioni';
export * from './corsi';
export * from './fatture';

export class RESPONSE{
    data;
    dati;
}

export class Giorni{
    numero;
    descrizione;
}

export class Iscrizioni{
    id_paziente;
    id_corso;
    rimanenti;
    data_inizio;
}

export class Iscrizioni_VIEW{
    id_iscrizione;
    id_paziente;
    cognome;
    nome;
    data_di_nascita;
    id_corso;
    descrizione;
    ora_inizio;
    ora_fine;
    giorno1;
    giorno2;
    giorno3;
    rimanenti;
    data_inizio;
    attivo;
}

export class Appunto{
    id_appunto;
    appunto;
    attivo;
}

export class Codici{
    id_iscrizione;
    codice;
}

export class Presenze_Iscrizioni{
    id_iscrizione;
    descrizione;
    data_inizio;
    rimanenti;
    presenze: Array<string>;
}


export class Verifica_Iscrizioni{
    paziente= new Paziente();
    iscrizioni: Array<Presenze_Iscrizioni>;
}

export class AttachFile{
    id_allegato;
    nome;
    tipo;
    obj;
}

export class DettaglioCorsi{
    attivi= new Array<Corsisti>();
    disattivi= new Array<Corsisti>();
}

export class Corsisti{
    id_iscrizione;
    codice;
    cognome;
    nome;
    telefono;
    email;
    rimanenti;
}

export class Evento{
    id;
    title;
    start;
    end;
    color;
    meta;
    actions;
}

export class CartellaClinica{
    disturbo;
    patologie_correlate;
    esami;
    terapia_correlata;
    altre_patologie;
    familiarita;
    altre_terapie;
    interventi;
    gravidanza;
    gravidanza_note;
    peacemaker;
    peacemaker_note;
    placche;
    placche_note;
    protesi;
    protesi_note;
    oncologico;
    oncologico_note;
    dializzato;
    dializzato_note;
    allergie;
    allergie_note;
    note;
    osservazione_posturale;
    segni;
    test_valutazione;
    trattamento;
    obiettivo;
    firma;
    data;    
}

export class PresenzeOGGI{
    cognome;
    nome;
    codice;
    descrizione;
    ora_inizio;
}