
<h1 [ngStyle]="{'color': data.evento.color.primary}">{{data.evento.title}}</h1>
<h5>{{data.evento.start | date: 'EEEE dd MMMM yyyy - HH:mm' }}</h5>
<div fxLayout="row" fxLayout.xs="column" fxFlexFill fxLayoutGap="10px">
    <div fxFlex="70%">
        <mat-card>
            <mat-card-subtitle>Pazienti attualmente iscritti: {{data.dettaglioCorso.attivi.length}}</mat-card-subtitle>
            <mat-card-content>
                <div fxLayout="row" fxLayout.xs="column" fxFlexFill fxLayoutGap="10px" *ngFor="let eleat of data.dettaglioCorso.attivi; let i=index">
                        <div fxFlex="100%">
                            {{i+1}}) {{eleat.cognome}} {{eleat.nome}}, {{eleat.telefono}}, {{eleat.email}} | Prestazioni rimanenti: {{eleat.rimanenti}} 
                        <br/>
                            <button mat-button (click)="Disattiva(eleat)"> Disattiva </button> | <button mat-button (click)="RegistraPresenza(eleat)">Registra Presenza</button>
                        </div>
                </div>
                <div fxLayout="row" fxLayout.xs="column" fxFlexFill fxLayoutGap="10px">
                    <div fxFlex="100%">
                        Data Presenza: <input type="date" matInput [(ngModel)]="data_presenza">
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div fxFlex="30%">
        <mat-card>
            <mat-card-subtitle>Iscrizioni scadute o disattivate</mat-card-subtitle>
            <mat-card-content>
                <div fxLayout="row" fxLayout.xs="column" fxFlexFill fxLayoutGap="10px" *ngFor="let eledat of data.dettaglioCorso.disattivi; let j=index">
                    <div fxFlex="100%">
                       {{j+1}}) {{eledat.cognome}} {{eledat.nome}}
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>
