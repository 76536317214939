import { NgModule } from '@angular/core';
import {LOCALE_ID} from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {MatCardModule} from '@angular/material/card';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatFormFieldModule} from '@angular/material/form-field';
import { FlexLayoutModule } from '@angular/flex-layout';
import {MatTooltipModule,MatTabsModule,MatSelectModule, MatBadgeModule, MatAutocompleteModule, MatSortModule, MatInputModule, MatButtonModule, MatIconModule, MatSidenavModule, MatListModule, MatToolbarModule, MatTableModule, MatMenuModule, MatSnackBarModule, MatDialogModule, MatCheckboxModule, MatTooltip, MatPaginatorModule } from '@angular/material';
// used to create fake backend
import { AuthGuard} from './_helpers';
import { NgxBarcodeModule } from 'ngx-barcode';

import { AppComponent } from './app.component';
import { appRoutingModule } from './app.routing';
import localeIt from '@angular/common/locales/it';
registerLocaleData(localeIt);

import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { HomeComponent } from './home';
import { LoginComponent } from './login';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthenticationService, GlobalServices } from './_services';
import { DashboardComponent} from './dashboard/dashboard.component';
import { PrestazioniComponent, DialogOverviewPrestazioniDialog } from './prestazioni/prestazioni.component';
import { CartellaClinicaDialog, DialogOverviewPazientiDialog, PazientiComponent } from './pazienti/pazienti.component';
import { DialogOverviewCorsiDialog, CorsiComponent } from './corsi/corsi.component';
import { IscrizioniComponent, DialogOverviewIscrizioniDialog } from './iscrizioni/iscrizioni.component';
import { FattureComponent } from './fatture/fatture.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { CalendarioComponent, DettaglioCorsiDialog, DettaglioEventiDialog  } from './calendario/calendario.component';
import { SignaturePadModule } from 'angular2-signaturepad'; 

@NgModule({
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    HttpClientModule,
    appRoutingModule,
    BrowserAnimationsModule,
    MatCardModule,
    MatGridListModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatSidenavModule,
    MatListModule,
    MatToolbarModule,
    MatTableModule,
    MatMenuModule,
    FormsModule,
    MatSnackBarModule,
    MatDialogModule,
    FlexLayoutModule,
    MatSelectModule,
    MatSortModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatBadgeModule,
    NgxBarcodeModule,
    MatTabsModule,
    MatTooltipModule,
    MatPaginatorModule,
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
    SignaturePadModule
],
declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    DashboardComponent,
    PrestazioniComponent,
    DialogOverviewPrestazioniDialog,
    DialogOverviewPazientiDialog,
    DialogOverviewCorsiDialog,
    DialogOverviewIscrizioniDialog,
    CartellaClinicaDialog,
    DettaglioCorsiDialog,
    DettaglioEventiDialog,
    PazientiComponent,
    CorsiComponent,
    IscrizioniComponent,
    FattureComponent,
    CalendarioComponent
],
providers: [
    { provide: LOCALE_ID, useValue: 'it-IT' },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    AuthGuard,
    AuthenticationService,
    GlobalServices
],
entryComponents: [DialogOverviewPrestazioniDialog],
bootstrap: [AppComponent]
})
export class AppModule { }
