<mat-card>
    <mat-card-title>
       <mat-icon color="primary">table_view</mat-icon> Elenco Corsi
    </mat-card-title>
    <mat-card-subtitle>
        In questa sezione puoi inserire nuovi corsi o gestire gli esistenti
    </mat-card-subtitle>
    <mat-card-content>
        <!-- SEZIONE INSERIMENTO NUOVO CORSO -->
        <mat-card>
            <mat-card-subtitle>
                <mat-icon style="color: green">add</mat-icon> &nbsp;Inserisci o Modifca Corso
            </mat-card-subtitle>
            <mat-card-content>
            <form [formGroup]="CorsiForm" (ngSubmit)="Salva()">
                <div fxLayout="row" fxLayout.xs="column" fxFlexFill fxLayoutGap="10px">
                    <div fxFlex="25%" fxFlexFill >
                        <mat-form-field class="example-full-width">
                            <mat-label>Descrizione</mat-label>
                            <input matInput [(ngModel)]="corso.descrizione" name="corso.descrizione" formControlName="descrizione" required>
                        </mat-form-field>
                    </div>
                    <div fxFlex="25%" fxFlexFill >
                        <mat-form-field class="example-full-width">
                            <mat-label>Ora Inizio</mat-label>
                            <input type="time" matInput [(ngModel)]="corso.ora_inizio" name="corso.ora_inizio" formControlName="ora_inizio" required>
                        </mat-form-field>
                    </div>
                    <div fxFlex="25%" fxFlexFill >
                        <mat-form-field class="example-full-width">
                            <mat-label>Ora Fine</mat-label>
                            <input type="time" matInput [(ngModel)]="corso.ora_fine" name="corso.ora_fine" formControlName="ora_fine" required>
                        </mat-form-field>
                    </div>
                    <div fxFlex="25%" fxFlexFill >
                        <mat-form-field class="example-full-width">
                            <mat-label>Numero massimo di partecipanti</mat-label>
                            <input type="number" min='0' matInput [(ngModel)]="corso.numero_partecipanti" name="corso.numero_partecipanti" formControlName="numero_partecipanti" required>
                        </mat-form-field>
                    </div>
                </div>
                <div fxLayout="row" fxLayout.xs="column" fxFlexFill fxLayoutGap="10px">
                    <div fxFlex="15%" fxFlexFill>
                        <mat-form-field class="example-full-width">
                            <mat-label>Giorno 1</mat-label>
                            <mat-select [(ngModel)]="corso.giorno1" name="corso.giorno1" formControlName="giorno1" required>
                              <mat-option *ngFor="let el of giorni" [value]="el.numero">{{el.descrizione}}</mat-option>
                            </mat-select>
                          </mat-form-field>
                    </div>
                    <div fxFlex="15%" fxFlexFill>
                        <mat-form-field class="example-full-width">
                            <mat-label>Giorno 2</mat-label>
                            <mat-select [(ngModel)]="corso.giorno2" name="corso.giorno2" formControlName="giorno2">
                              <mat-option *ngFor="let el of giorni" [value]="el.numero">{{el.descrizione}}</mat-option>
                            </mat-select>
                          </mat-form-field>
                    </div>
                    <div fxFlex="15%" fxFlexFill>
                        <mat-form-field class="example-full-width">
                            <mat-label>Giorno 3</mat-label>
                            <mat-select [(ngModel)]="corso.giorno3" name="corso.giorno3" formControlName="giorno3">
                              <mat-option *ngFor="let el of giorni" [value]="el.numero">{{el.descrizione}}</mat-option>
                            </mat-select>
                          </mat-form-field>
                    </div>
                    <div fxFlex="25%" fxFlexFill >
                        <mat-form-field class="example-full-width">
                            <mat-label>Colore bordo (per Calendario)</mat-label>
                            <input type="color" matInput [(ngModel)]="corso.color.primary" name="corso.color.primary" formControlName="color_primary" required>
                        </mat-form-field>
                    </div>
                    <div fxFlex="25%" fxFlexFill >
                        <mat-form-field class="example-full-width">
                            <mat-label>Colore sfondo (per Calendario)</mat-label>
                            <input type="color" matInput [(ngModel)]="corso.color.secondary" name="corso.color.secondary" formControlName="color_secondary" required>
                        </mat-form-field>
                    </div>
                </div>
                
                <div fxLayout="row" fxLayout.xs="column" fxFlexFill fxLayoutGap="10px">
                    <div fxFlex="100%">
                        <button fxFlexFill mat-raised-button type="submit" color="primary" [disabled]="!CorsiForm.valid">Salva</button>
                    </div>
                </div>
            </form>
            </mat-card-content>
        </mat-card>
        <!-- FINE SEZIONE INSERIMENTO NUOVO PAZIENTE -->
        <!-- SEZIONE TABELLA PAZIENTI -->
        <table mat-table [dataSource]="elencoCorsi" multiTemplateDataRows matSort>

            <ng-container matColumnDef="descrizione">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Descrizione </th>
              <td mat-cell *matCellDef="let element" > <b [ngStyle]="{'color': element.color.secondary}">{{element.descrizione}}</b> &nbsp;<mat-icon [ngStyle]="{'color': element.color.primary}">donut_large</mat-icon></td>
            </ng-container>
          
            <ng-container matColumnDef="ora_inizio">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Ora Inizio </th>
              <td mat-cell *matCellDef="let element"> {{element.ora_inizio}} </td>
            </ng-container>
          
            <ng-container matColumnDef="ora_fine">
              <th mat-header-cell *matHeaderCellDef> Ora Fine </th>
              <td mat-cell *matCellDef="let element"> {{element.ora_fine}} </td>
            </ng-container>

            <ng-container matColumnDef="giorno1">
                <th mat-header-cell *matHeaderCellDef mat-sort-header > Giorno 1 </th>
                <td mat-cell *matCellDef="let element"> {{get_giorno(element.giorno1)}} </td>
            </ng-container>

            <ng-container matColumnDef="giorno2">
                <th mat-header-cell *matHeaderCellDef mat-sort-header > Giorno 2 </th>
                <td mat-cell *matCellDef="let element"> {{get_giorno(element.giorno2)}} </td>
            </ng-container>

            <ng-container matColumnDef="giorno3">
                <th mat-header-cell *matHeaderCellDef mat-sort-header > Giorno 3 </th>
                <td mat-cell *matCellDef="let element"> {{get_giorno(element.giorno3)}} </td>
            </ng-container>

            <ng-container matColumnDef="numero_partecipanti">
              <th mat-header-cell *matHeaderCellDef > Max partecipanti </th>
              <td mat-cell *matCellDef="let element"> {{element.numero_partecipanti}} </td>
            </ng-container>

            <ng-container matColumnDef="azioni">
                <th mat-header-cell *matHeaderCellDef > Azioni </th>
                <td mat-cell *matCellDef="let element">
                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                        <mat-icon>more_vert</mat-icon>
                    </button> 
                    <mat-menu #menu="matMenu">
                        <button (click)="Elimina(element)" mat-menu-item><mat-icon mat-list-icon style="color: red">delete</mat-icon>Elimina</button>
                        <button (click)="Modifica(element)" mat-menu-item><mat-icon mat-list-icon style="color:goldenrod">edit</mat-icon>Modifica</button>
                    </mat-menu>
                </td>
              </ng-container>
          
              <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                    <div [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'" class="example-element-detail">
                        <div fxLayout="row" fxLayout.xs="column" fxFlexFill fxLayoutGap="10px">
                            <div fxFlex="50%">
                                <mat-card>
                                    <mat-card-subtitle>Pazienti attualmente iscritti: {{dettaglioCorso.attivi.length}}</mat-card-subtitle>
                                    <mat-card-content>
                                        <div fxLayout="row" fxLayout.xs="column" fxFlexFill fxLayoutGap="10px" *ngFor="let eleat of dettaglioCorso.attivi; let i=index">
                                            <div fxFlex="100%">
                                                {{i+1}}) {{eleat.cognome}} {{eleat.nome}}, {{eleat.telefono}}, {{eleat.email}} | Prestazioni rimanenti: {{eleat.rimanenti}}
                                            </div>
                                        </div>
                                    </mat-card-content>
                                </mat-card>
                            </div>
                            <div fxFlex="50%">
                                <mat-card>
                                    <mat-card-subtitle>Iscrizioni scadute o disattivate</mat-card-subtitle>
                                    <mat-card-content>
                                        <div fxLayout="row" fxLayout.xs="column" fxFlexFill fxLayoutGap="10px" *ngFor="let eledat of dettaglioCorso.disattivi; let j=index">
                                            <div fxFlex="100%">
                                               {{j+1}}) {{eledat.cognome}} {{eledat.nome}}, {{eledat.telefono}}, {{eledat.email}}
                                            </div>
                                        </div>
                                    </mat-card-content>
                                </mat-card>
                            </div>
                        </div>
                    </div>
                </td>
              </ng-container>
            
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let element; columns: displayedColumns;" 
              class="example-element-row"
              [class.example-expanded-row]="expandedElement === element" 
              (click)="MostraDettagli(element)"></tr>
              <tr mat-row *matRowDef="let row; columns: ['expandedDetail']"></tr>
          </table>
        <!-- FINE SEZIONE TABELLA PAZIENTI -->
    </mat-card-content>
</mat-card>

