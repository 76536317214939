import { Component, OnInit, ChangeDetectionStrategy,  ViewChild,  TemplateRef, ChangeDetectorRef, OnChanges, Inject} from '@angular/core';
import {Appunto, Giorni,Corsi,Corsisti, RESPONSE, Codici, Presenze_Iscrizioni, Verifica_Iscrizioni, Paziente, DettaglioCorsi, Prestazioni, Evento} from '../_models';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import{GlobalServices} from '../_services';
import { DialogOverviewIscrizioniDialog, IscrizioniComponent } from '../iscrizioni/iscrizioni.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours,
} from 'date-fns';
import { Subject } from 'rxjs';
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarView,
  CalendarMonthViewBeforeRenderEvent,
  CalendarWeekViewBeforeRenderEvent,
  CalendarDayViewBeforeRenderEvent
} from 'angular-calendar';
import { ViewPeriod } from 'calendar-utils';
import RRule from 'rrule';
import moment from 'moment-timezone';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { startWith } from 'rxjs/operators';

export interface DialogData {
  dettaglioCorso: DettaglioCorsi;
  evento: CalendarEvent;
}

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#f9e1cd',
    secondary: '#f9e1cd',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
  privato:{
    primary: '#20D2c4',
    secondary:'#20d2c4',
  }
};

interface RecurringEvent {
  id;
  title: string;
  color: any;
  rrule?: {
    freq: any;
    bymonth?: number;
    bymonthday?: number;
    byweekday?: any;
  };
  start;
  end;
}

@Component({
  selector: 'app-calendario',
  templateUrl: './calendario.component.html',
  styleUrls: ['./calendario.component.scss']
})
export class CalendarioComponent implements OnInit {

   //variabili calendario
   view: CalendarView = CalendarView.Week;
   excludeDays: number[] = [0,6];
   CalendarView = CalendarView;
   viewDate = moment().toDate();
   elencoCorsi;
   recurringEvents= new Array<RecurringEvent>();
   viewPeriod: ViewPeriod;
   dettaglioCorso: DettaglioCorsi;
   brush=false;
   refresh: Subject<any> = new Subject();
   activeDayIsOpen: boolean = true;
 
   dayStartHour = 7;
 
   dayEndHour = 22;
   
   AppuntamentoForm=new FormGroup({
     id_appuntamento: new FormControl(),
     id_paziente: new FormControl(),
     id_prestazione: new FormControl(),
     data: new FormControl(),
     ora: new FormControl(),
     note: new FormControl()
   })
   pazienti: Paziente[];
   elencoPrestazioni: Prestazioni[];
   actions: CalendarEventAction[] = [
     /*{
       label: '<img src="https://gestionale.fisiokineticalorusso.it/res/edit.png" height="50%">',
       a11yLabel: 'Edit',
       onClick: ({ event }: { event: CalendarEvent }): void => {
         this.handleEvent('Edited', event);
       },
     },*/
     {
       label: '<img src="https://gestionale.fisiokineticalorusso.it/res/delete.png" height="50%">',
       a11yLabel: 'Delete',
       onClick: ({ event }: { event: CalendarEvent }): void => {
         this.events = this.events.filter((iEvent) => iEvent !== event);
         this.handleEvent('Deleted', event);
       },
     },
   ];
 
   
   events: CalendarEvent[]; 
   appuntamenti_eventi: CalendarEvent[];

  constructor(private service: GlobalServices, private _snackBar: MatSnackBar, private cdr: ChangeDetectorRef,public dialog: MatDialog ) { }

  ngOnInit(): void {
    this.CaricaCorsi();
    this.CaricaPazienti();
    this.CaricaPrestazioni();
    this.CaricaAppuntamenti();
  }

  SalvaAppuntamento(){
    this.service.post_function(JSON.stringify(this.AppuntamentoForm.value), this.service.apiAppuntamenti).subscribe(
      (data: RESPONSE)=> {
        if(data.data='OK')
        this._snackBar.open('Operazione eseguita correttamente', 'OK', {
          duration: 3000,
          verticalPosition: 'top'
        });
        this.CaricaAppuntamenti();
      }
    )
  }


  interfacciarrule(day){
    switch(day){
      case "1": return RRule.SU;break;
      case "2": return RRule.MO;break;
      case "3": return RRule.TU;break;
      case "4": return RRule.WE;break;
      case "5": return RRule.TH;break;
      case "6": return RRule.FR;break;
      case "7": return RRule.SA;break;
    }
  }

  CaricaAppuntamenti(){
    this.appuntamenti_eventi=[];
    this.service.get_function(this.service.apiAppuntamenti).subscribe(
      (appuntamenti: any[])=>
      {
        appuntamenti.forEach(appuntamento=>{
          let aid=appuntamento.id_prenotazione;
          let atitle=appuntamento.cognome+' '+appuntamento.nome+' - '+appuntamento.descrizione;
          let startDate=moment(appuntamento.data).toDate();
          let color;
          if(appuntamento.email=="maria.fisioterapista@gmail.com") color=colors.privato;
          else color=colors.blue;
          startDate.setHours(appuntamento.ora.split(":")[0]);
          startDate.setMinutes(appuntamento.ora.split(":")[1]);
          let endDate=startDate;
          endDate=moment(endDate).add(appuntamento.durata.split(":")[0],'h').add(appuntamento.durata.split(":")[1],'m').toDate();
          let ameta={type: "B", telefono: appuntamento.telefono, email: appuntamento.email, note: appuntamento.note};
          this.appuntamenti_eventi.push({id:aid,title:atitle,start:startDate,end:endDate,meta:ameta,actions:this.actions, color: color, cssClass:'my-custom-class',});
        });
        this.setView(CalendarView.Month);
        this.cdr.detectChanges();
        this.setView(CalendarView.Week);
        this.cdr.detectChanges();
      }
    )
  }

  CaricaPazienti()
  {
      this.service.get_function(this.service.apiPazienti).subscribe(
      (pazienti: Paziente[])=>
      {
        this.pazienti=pazienti;
      }
    )
  }

  CaricaPrestazioni()
  {
    this.service.get_function(this.service.apiPrestazioni).subscribe(
      (prestazioni: Prestazioni[])=>
      {
        this.elencoPrestazioni=prestazioni;
      }
    )
  }

  CaricaCorsi(){
    this.service.get_function(this.service.apiCorsi).subscribe(
      (corsi: Array<any>)=>
      {
        corsi.forEach(element=>{
          let wd=new Array<any>();
          if(element.giorno1!='0') wd.push(this.interfacciarrule(element.giorno1));
          if(element.giorno2!='0') wd.push(this.interfacciarrule(element.giorno2));
          if(element.giorno3!='0') wd.push(this.interfacciarrule(element.giorno3));
          this.recurringEvents.push({'id':element.id_corso,'title':element.descrizione,'color':JSON.parse(element.color),'rrule':{'freq':RRule.WEEKLY,'byweekday':wd},'start':element.ora_inizio,'end':element.ora_fine})
        });
        this.setView(CalendarView.Month);
        this.cdr.detectChanges();
        this.setView(CalendarView.Week);
        this.cdr.detectChanges();
      }
    )
  }

    //funzioni calendario
    dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
      if (isSameMonth(date, this.viewDate)) {
        if (
          (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
          events.length === 0
        ) {
          this.activeDayIsOpen = false;
        } else {
          this.activeDayIsOpen = true;
        }
        this.viewDate = date;
      }
    }
  
    eventTimesChanged({
      event,
      newStart,
      newEnd,
    }: CalendarEventTimesChangedEvent): void {
      this.events = this.events.map((iEvent) => {
        if (iEvent === event) {
          return {
            ...event,
            start: newStart,
            end: newEnd,
          };
        }
        return iEvent;
      });
      this.handleEvent('Dropped or resized', event);
    }
  
    handleEvent(action: string, event: CalendarEvent): void {
      if(action=="Deleted") this.deleteEvent(event);
      else{
        if(event.meta.type=='A'){
          this.dettaglioCorso= new DettaglioCorsi();
          this.service.get_function(this.service.apiDettaglioCorsi+event.id).subscribe(
            (data:Array<Corsisti>)=>
            {
              data.forEach(element=>{
                if(element.rimanenti==0) this.dettaglioCorso.disattivi.push(element);
                else this.dettaglioCorso.attivi.push(element);
              })
              const dialogRef = this.dialog.open(DettaglioCorsiDialog, {
                width: '800px',
                data: {dettaglioCorso: this.dettaglioCorso, evento: event}
              });
            }
          );
      }
      else
      {
        const dialogRef = this.dialog.open(DettaglioEventiDialog, {
          width: '800px',
          data: {dettaglioCorso: " ", evento: event}
        });
      }
      }
      
      
      /*this.modalData = { event, action };
      this.modal.open(this.modalContent, { size: 'lg' });*/
      //gestisci evento
    }
  
    addEvent(): void {
      this.events = [
        ...this.events,
        {
          title: 'New event',
          start: startOfDay(new Date()),
          end: endOfDay(new Date()),
          color: colors.red,
          draggable: true,
          resizable: {
            beforeStart: true,
            afterEnd: true,
          },
        },
      ];
    }
  
    deleteEvent(eventToDelete: CalendarEvent) {
      this.service.delete_function(this.service.apiAppuntamenti+eventToDelete.id).subscribe(
        data=>{if(data) this._snackBar.open('Operazione eseguita correttamente', 'OK', {
          duration: 3000,
          verticalPosition: 'top'
        });}
      )
      
    }
  
    setView(view: CalendarView) {
      this.view = view;
    }
  
    closeOpenMonthViewDay() {
      this.activeDayIsOpen = false;
    }
  
    updateCalendarEvents(
      viewRender:
        | CalendarMonthViewBeforeRenderEvent
        | CalendarWeekViewBeforeRenderEvent
        | CalendarDayViewBeforeRenderEvent
    ): void {
      if (
        !this.viewPeriod ||
        !moment(this.viewPeriod.start).isSame(viewRender.period.start) ||
        !moment(this.viewPeriod.end).isSame(viewRender.period.end)
      ) {
        this.viewPeriod = viewRender.period;
        this.events = [];
        this.appuntamenti_eventi.forEach(x=>this.events.push(x));
        this.recurringEvents.forEach((event) => {
          const rule: RRule = new RRule({
            ...event.rrule,
            dtstart: moment(viewRender.period.start).startOf('day').toDate(),
            until: moment(viewRender.period.end).endOf('day').toDate(),
          });
          const { title, color,id } = event;
          
          rule.all().forEach((date) => {
            let startDate=moment(date).toDate();
            let endDate=moment(date).toDate();
            startDate.setHours(event.start.split(':')[0]);
            startDate.setMinutes(event.start.split(':')[1]);
            endDate.setHours(event.end.split(':')[0]);
            endDate.setMinutes(event.end.split(':')[1]);
            this.events.push({
              id,
              title,
              color,
              start: startDate,
              end: endDate,
              meta: {type:'A'},
              cssClass:'my-custom-class',
            });
          });
        });
        this.cdr.detectChanges();
      }
    }

}

export class X {
  id_iscrizione;
  codice;
  data;
}

@Component({
  selector: 'dettaglio-corsi',
  templateUrl: 'dettaglio-corsi.html',
})
export class DettaglioCorsiDialog {
  data_presenza: any;
  temp= new X;
  

  constructor(private service: GlobalServices,
    public dialogRef: MatDialogRef<DettaglioCorsiDialog>,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  Disattiva(element){
    const dialogRef = this.dialog.open(DialogOverviewIscrizioniDialog, {
      width: '250px',
      data: {iscrizione: element}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) 
      this.service.delete_function(this.service.apiIscrizioni+element.id_iscrizione).subscribe(
        (data: RESPONSE)=> {
          if(data.data='OK')
          this.dialogRef.close();
          });
        }
      )
    }

    RegistraPresenza(element){
      if(!this.data_presenza) window.alert("inserire una data valida");
      else 
      {
        this.temp.id_iscrizione=element.id_iscrizione;
        this.temp.codice=element.codice;
        this.temp.data=this.data_presenza;
        this.service.post_function(JSON.stringify(this.temp), this.service.apiPresenzaBis).subscribe(
          (data: RESPONSE)=> {
            if(data.data='OK') 
            {
              this._snackBar.open('Operazione eseguita correttamente', 'OK', {
                    duration: 3000,
                    verticalPosition: 'top'
                    })
            }
            else
            {
              this._snackBar.open('Problemi durante l\'operazione', 'OK', {
                    duration: 3000,
                    verticalPosition: 'top'
                    })
            }
                  }
            );
      }
      }
  }

@Component({
  selector: 'dettaglio-eventi',
  templateUrl: 'dettaglio-eventi.html',
})
export class DettaglioEventiDialog {

  constructor(
    public dialogRef: MatDialogRef<DettaglioEventiDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

}
