import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService, GlobalServices } from '../_services';
import {JwtHelperService} from '@auth0/angular-jwt';

@Injectable()
export class AuthGuard implements CanActivate {
    jwtHelper: JwtHelperService=new JwtHelperService();

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private service: GlobalServices
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let token = sessionStorage.getItem('token');
        let access_level : Array<number> =  route.data['access_level'];
        let my_acl: number = this.service.get_access_level();
        if (token) {

            // c'è un token, faccio i controlli
            if (!this.jwtHelper.isTokenExpired(token)){
                let data_scadenza = this.jwtHelper.getTokenExpirationDate(token);
                //console.log(data_scadenza)
                
                // se level_ok è > 0 vuol dire in quella rotta è stato definito come minimo il livello di accesso dell'utente loggato
                let level_ok=0;
                for (var i=0; i<access_level.length; i++) {
                    if (my_acl==access_level[i]) level_ok++;
                }

                if (level_ok>0) return true;
                else window.alert({title: 'Attenzione', message: 'Non hai accesso a questa sezione', closeButton: 'Chiudi'});
                this.router.navigate(['/']);

            }else{
                this.authenticationService.logout();
                this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
                return false;
            }
            
        }else{
            // non loggato (token non trovato)
            //console.log('token non trovato')
            this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
            return false;
        }
    }
}