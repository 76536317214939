import { Component, OnInit, Inject, ViewChild, AfterViewInit } from '@angular/core';
import {Corsi, RESPONSE, Giorni, DettaglioCorsi, Corsisti} from '../_models';
import{GlobalServices} from '../_services';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {FormControl, FormGroup} from '@angular/forms';
import {animate, state, style, transition, trigger} from '@angular/animations';

export interface DialogData {
  corso: Corsi;
}

@Component({
  selector: 'app-corsi',
  templateUrl: './corsi.component.html',
  styleUrls: ['./corsi.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class CorsiComponent implements AfterViewInit {

  CorsiForm= new FormGroup({
    descrizione: new FormControl(),
    ora_inizio: new FormControl(),
    ora_fine: new FormControl(),
    numero_partecipanti: new FormControl(),
    giorno1: new FormControl(),
    giorno2: new FormControl(),
    giorno3: new FormControl(),
    color_primary: new FormControl(),
    color_secondary: new FormControl(),
  })
  expandedElement: Corsi | null;
  cors= new Array<Corsi>();
  giorni= new Array<Giorni>();
  corso= new Corsi();
  dettaglioCorso= new DettaglioCorsi();
  displayedColumns: string[] = ['descrizione', 'ora_inizio', 'ora_fine', 'giorno1', 'giorno2', 'giorno3', 'numero_partecipanti','azioni'];
  elencoCorsi = new MatTableDataSource(this.cors);
  @ViewChild(MatSort) sort: MatSort;

  constructor(private service: GlobalServices, private _snackBar: MatSnackBar, public dialog: MatDialog) { }

  ngAfterViewInit(): void {
    this.service.get_function(this.service.apiGiorni).subscribe(
      (giorni: Giorni[])=>
      {
        this.giorni=giorni;
        this.CaricaCorsi();
      }
    )
  }

  CaricaCorsi(){
    this.service.get_function(this.service.apiCorsi).subscribe(
      (corsi: Array<any>)=>
      {
        for(var i=0; i< corsi.length;i++) corsi[i].color=JSON.parse(corsi[i].color);
        this.elencoCorsi=new MatTableDataSource(corsi);
        setTimeout(()=>{this.elencoCorsi.sort=this.sort;});
      }
    )
  }

  Modifica(element){
    this.corso=element;
    //this.corso.color=JSON.parse(element.color);
  }

  MostraDettagli(obj: Corsi){
    this.dettaglioCorso= new DettaglioCorsi();
    this.expandedElement = this.expandedElement === obj ? null : obj;
    this.service.get_function(this.service.apiDettaglioCorsi+obj.id_corso).subscribe(
      (data:Array<Corsisti>)=>
      {
        data.forEach(element=>{
          if(element.rimanenti==0) this.dettaglioCorso.disattivi.push(element);
          else this.dettaglioCorso.attivi.push(element);
        })
      }
    )
  }

  get_giorno(x){
    let giorno= new Giorni();
    giorno=this.giorni.find(function(element){return element.numero==x});
    return giorno.descrizione;
  }

  Salva(){
    this.service.post_function(JSON.stringify(this.corso), this.service.apiCorsi).subscribe(
      (data: RESPONSE)=> {
        if(data.data='OK')
        this._snackBar.open('Operazione eseguita correttamente', 'OK', {
          duration: 3000,
          verticalPosition: 'top'
        });
        this.corso=new Corsi();
        this.CaricaCorsi();
      }
    )
  }

  Elimina(element: Corsi){

    const dialogRef = this.dialog.open(DialogOverviewCorsiDialog, {
      width: '250px',
      data: {corso: element}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) 
      this.service.delete_function(this.service.apiCorsi+element.id_corso).subscribe(
        (data: RESPONSE)=> {
          if(data.data='OK')
          this._snackBar.open('Operazione eseguita correttamente', 'OK', {
            duration: 3000,
            verticalPosition: 'top'
          });
          this.corso=new Corsi();
          this.CaricaCorsi();
        }
      )
    });
  }


}

@Component({
  selector: 'dialog-overview-example-dialog',
  templateUrl: 'dialog-overview-example-dialog.html',
})
export class DialogOverviewCorsiDialog {

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewCorsiDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

}
