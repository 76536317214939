<h1 mat-dialog-title>
    Cartella Clinica del Paziente
</h1>
    <div mat-dialog-content>
        <div fxLayout="column" fxFlexFill fxLayoutGap="5px">
            <div fxFlex="100%">
                <h1 class="titolo">ANAMNESI</h1>
            </div>
            <div fxFlex="100%" fxFlexFill>
                <div fxLayout="column">
                    <dix fxFlex="100%" class="titolino">Disturbo lamentato dal Paziente</dix>
                    <div fxFlex="100%" ><textarea [disabled]="firma_mancante" class="cento" rows="5" [(ngModel)]="data.cartella_clinica.disturbo" name="data.cartella_clinica.disturbo"></textarea></div>
                </div>
            </div>
            <div fxFlex="100%" fxFlexFill>
                <div fxLayout="column">
                    <dix fxFlex="100%" class="titolino">Patologie pregresse correlate</dix>
                    <div fxFlex="100%" ><textarea [disabled]="firma_mancante"  class="cento" rows="5" [(ngModel)]="data.cartella_clinica.patologie_correlate" name="data.cartella_clinica.patologie_correlate"></textarea></div>
                </div>
            </div>
            <div fxFlex="100%" fxFlexFill>
                <div fxLayout="column">
                    <dix fxFlex="100%" class="titolino">Esami diagnostici effettuati</dix>
                    <div fxFlex="100%" ><textarea [disabled]="firma_mancante"  class="cento" rows="5" [(ngModel)]="data.cartella_clinica.esami" name="data.cartella_clinica.esami"></textarea></div>
                </div>
            </div>
            <div fxFlex="100%" fxFlexFill>
                <div fxLayout="column">
                    <dix fxFlex="100%" class="titolino">Terapia farmacologica in corso per il disturbo lamentato</dix>
                    <div fxFlex="100%" ><textarea [disabled]="firma_mancante"  class="cento" rows="5" [(ngModel)]="data.cartella_clinica.terapia_correlata" name="data.cartella_clinica.terapia_correlata"></textarea></div>
                </div>
            </div>
            <div fxFlex="100%">
                <h1 class="titolo">STORIA CLINICA</h1>
            </div>
            <div fxFlex="100%" fxFlexFill>
                <div fxLayout="column">
                    <dix fxFlex="100%" class="titolino">Altre patologie conosciute</dix>
                    <div fxFlex="100%" ><textarea [disabled]="firma_mancante"  class="cento" rows="5" [(ngModel)]="data.cartella_clinica.altre_patologie" name="data.cartella_clinica.altre_patologie"></textarea></div>
                </div>
            </div>
            <div fxFlex="100%" fxFlexFill>
                <div fxLayout="column">
                    <dix fxFlex="100%" class="titolino">Familiarità</dix>
                    <div fxFlex="100%" ><textarea [disabled]="firma_mancante"  class="cento" rows="5" [(ngModel)]="data.cartella_clinica.familiarita" name="data.cartella_clinica.familiarita"></textarea></div>
                </div>
            </div>
            <div fxFlex="100%" fxFlexFill>
                <div fxLayout="column">
                    <dix fxFlex="100%" class="titolino">Terapia farmacologica in corso</dix>
                    <div fxFlex="100%" ><textarea [disabled]="firma_mancante"  class="cento" rows="5" [(ngModel)]="data.cartella_clinica.altre_terapie" name="data.cartella_clinica.altre_terapie"></textarea></div>
                </div>
            </div>
            <div fxFlex="100%" fxFlexFill>
                <div fxLayout="column">
                    <dix fxFlex="100%" class="titolino">Interventi</dix>
                    <div fxFlex="100%" ><textarea [disabled]="firma_mancante"  class="cento" rows="5" [(ngModel)]="data.cartella_clinica.interventi" name="data.cartella_clinica.interventi"></textarea></div>
                </div>
            </div>
            <div fxFlex="100%" fxFlexFill>
                <div fxLayout="row">
                    <dix fxFlex="70%" class="titolino">
                        <mat-label>Gravidanza</mat-label>
                        <mat-select [disabled]="firma_mancante"  [(ngModel)]="data.cartella_clinica.gravidanza" name="data.cartella_clinica.gravidanza" >
                          <mat-option value="Si">Si</mat-option>
                          <mat-option value="No">No</mat-option>
                          <mat-option value="Forse">Forse</mat-option>
                        </mat-select>
                    </dix>
                    <div fxFlex="30%" > <mat-label class="note">Note</mat-label><textarea [disabled]="firma_mancante"  class="cento" rows="2" [(ngModel)]="data.cartella_clinica.gravidanza_note" name="data.cartella_clinica.gravidanza_note"></textarea></div>
                </div>
            </div>
            <div fxFlex="100%" fxFlexFill>
                <div fxLayout="row">
                    <dix fxFlex="70%" class="titolino">
                        <mat-label>Portatore Peacemaker</mat-label>
                        <mat-select [disabled]="firma_mancante"  [(ngModel)]="data.cartella_clinica.peacemaker" name="data.cartella_clinica.peacemaker" >
                          <mat-option value="Si">Si</mat-option>
                          <mat-option value="No">No</mat-option>
                          <mat-option value="Forse">Forse</mat-option>
                        </mat-select>
                    </dix>
                    <div fxFlex="30%" > <mat-label class="note">Note</mat-label><textarea [disabled]="firma_mancante"  class="cento" rows="2" [(ngModel)]="data.cartella_clinica.peacemaker_note" name="data.cartella_clinica.peacemaker_note"></textarea></div>
                </div>
            </div>
            <div fxFlex="100%" fxFlexFill>
                <div fxLayout="row">
                    <dix fxFlex="70%" class="titolino">
                        <mat-label>Placche metalliche</mat-label>
                        <mat-select [disabled]="firma_mancante"  [(ngModel)]="data.cartella_clinica.placche" name="data.cartella_clinica.placche" >
                          <mat-option value="Si">Si</mat-option>
                          <mat-option value="No">No</mat-option>
                          <mat-option value="Forse">Forse</mat-option>
                        </mat-select>
                    </dix>
                    <div fxFlex="30%" > <mat-label class="note">Note</mat-label><textarea [disabled]="firma_mancante"  class="cento" rows="2" [(ngModel)]="data.cartella_clinica.placche_note" name="data.cartella_clinica.placche_note"></textarea></div>
                </div>
            </div>
            <div fxFlex="100%" fxFlexFill>
                <div fxLayout="row">
                    <dix fxFlex="70%" class="titolino">
                        <mat-label>Protesi</mat-label>
                        <mat-select [disabled]="firma_mancante"  [(ngModel)]="data.cartella_clinica.protesi" name="data.cartella_clinica.protesi" >
                          <mat-option value="Si">Si</mat-option>
                          <mat-option value="No">No</mat-option>
                          <mat-option value="Forse">Forse</mat-option>
                        </mat-select>
                    </dix>
                    <div fxFlex="30%" > <mat-label class="note">Note</mat-label><textarea [disabled]="firma_mancante"  class="cento" rows="2" [(ngModel)]="data.cartella_clinica.protesi_note" name="data.cartella_clinica.protesi_note"></textarea></div>
                </div>
            </div>
            <div fxFlex="100%" fxFlexFill>
                <div fxLayout="row">
                    <dix fxFlex="70%" class="titolino">
                        <mat-label>Paziente Oncologico</mat-label>
                        <mat-select [disabled]="firma_mancante"  [(ngModel)]="data.cartella_clinica.oncologico" name="data.cartella_clinica.oncologico" >
                          <mat-option value="Si">Si</mat-option>
                          <mat-option value="No">No</mat-option>
                          <mat-option value="Forse">Forse</mat-option>
                        </mat-select>
                    </dix>
                    <div fxFlex="30%" > <mat-label class="note">Note</mat-label><textarea [disabled]="firma_mancante"  class="cento" rows="2" [(ngModel)]="data.cartella_clinica.oncologico_note" name="data.cartella_clinica.oncologico_note"></textarea></div>
                </div>
            </div>
            <div fxFlex="100%" fxFlexFill>
                <div fxLayout="row">
                    <dix fxFlex="70%" class="titolino">
                        <mat-label>Paziente dializzato</mat-label>
                        <mat-select [disabled]="firma_mancante"  [(ngModel)]="data.cartella_clinica.dializzato" name="data.cartella_clinica.dializzato" >
                          <mat-option value="Si">Si</mat-option>
                          <mat-option value="No">No</mat-option>
                          <mat-option value="Forse">Forse</mat-option>
                        </mat-select>
                    </dix>
                    <div fxFlex="30%" > <mat-label class="note">Note</mat-label><textarea [disabled]="firma_mancante"  class="cento" rows="2" [(ngModel)]="data.cartella_clinica.dializzato_note" name="data.cartella_clinica.dializzato_note"></textarea></div>
                </div>
            </div>
            <div fxFlex="100%" fxFlexFill>
                <div fxLayout="row">
                    <dix fxFlex="70%" class="titolino">
                        <mat-label>Allergie</mat-label>
                        <mat-select [disabled]="firma_mancante"  [(ngModel)]="data.cartella_clinica.allergie" name="data.cartella_clinica.allergie" >
                          <mat-option value="Si">Si</mat-option>
                          <mat-option value="No">No</mat-option>
                          <mat-option value="Forse">Forse</mat-option>
                        </mat-select>
                    </dix>
                    <div fxFlex="30%" > <mat-label class="note">Note</mat-label><textarea [disabled]="firma_mancante"  class="cento" rows="2" [(ngModel)]="data.cartella_clinica.allergie_note" name="data.cartella_clinica.allergie_note"></textarea></div>
                </div>
            </div>
            <div fxFlex="100%" fxFlexFill>
                <div fxLayout="column">
                    <dix fxFlex="100%" class="titolino">Note</dix>
                    <div fxFlex="100%" ><textarea [disabled]="firma_mancante"  class="cento" rows="5" [(ngModel)]="data.cartella_clinica.note" name="data.cartella_clinica.note"></textarea></div>
                </div>
            </div>
            <div fxFlex="100%">
                <h1 class="titolo">VALUTAZIONE FISIOTERAPICA</h1>
            </div>
            <div fxFlex="100%" fxFlexFill>
                <div fxLayout="column">
                    <dix fxFlex="100%" class="titolino">Osservazione Posturale</dix>
                    <div fxFlex="100%" ><textarea [disabled]="firma_mancante"  class="cento" rows="5" [(ngModel)]="data.cartella_clinica.osservazione_posturale" name="data.cartella_clinica.osservazione_posturale"></textarea></div>
                </div>
            </div>
            <div fxFlex="100%" fxFlexFill>
                <div fxLayout="column">
                    <dix fxFlex="100%" class="titolino">Segni / Sintomi</dix>
                    <div fxFlex="100%" ><textarea [disabled]="firma_mancante"  class="cento" rows="5" [(ngModel)]="data.cartella_clinica.segni" name="data.cartella_clinica.segni"></textarea></div>
                </div>
            </div>
            <div fxFlex="100%" fxFlexFill>
                <div fxLayout="column">
                    <dix fxFlex="100%" class="titolino">Test di valutazione</dix>
                    <div fxFlex="100%" ><textarea [disabled]="firma_mancante"  class="cento" rows="5" [(ngModel)]="data.cartella_clinica.test_valutazione" name="data.cartella_clinica.test_valutazione"></textarea></div>
                </div>
            </div>
            <div fxFlex="100%" fxFlexFill>
                <div fxLayout="column">
                    <dix fxFlex="100%" class="titolino">Tipo di trattamento</dix>
                    <div fxFlex="100%" ><textarea [disabled]="firma_mancante"  class="cento" rows="5" [(ngModel)]="data.cartella_clinica.trattamento" name="data.cartella_clinica.trattamento"></textarea></div>
                </div>
            </div>
            <div fxFlex="100%" fxFlexFill>
                <div fxLayout="column">
                    <dix fxFlex="100%" class="titolino">Obbiettivo di trattamento</dix>
                    <div fxFlex="100%" ><textarea [disabled]="firma_mancante"  class="cento" rows="5" [(ngModel)]="data.cartella_clinica.obiettivo" name="data.cartella_clinica.obiettivo"></textarea></div>
                </div>
            </div>
            <div fxFlex="100%" fxFlexFill>
                <div fxLayout="column">
                    <dix fxFlex="100%" class="titolino">Dichiarazione del Consenso</dix>
                    <div fxFlex="100%">
                        <p>Sottoscrivendo il presente documento dichiaro:</p>
                        <p>1)che tutte le informazioni fornite sono state riportate in maniera esatta dalla dott.ssa Maria
                            Lorusso;<br/>
                            2) che tutte le informazioni fornite al momento della sottoscrizione del presente documento
                            risultano vere;<br/>
                            3) che non sussiste nessuna altra causa di impedimento alla terapia proposta taciuta alla dott.ssa
                            Lorusso;<br/>
                            4) che nel caso venga a conoscenza di nuove informazioni sulla mia salute importanti dal punto di
                            vista fisioterapico metterò al corrente la dott.ssa Lorusso per aggiornare il presente documento;<br/>
                            5) di aver preso visione dell'informativa della Privacy esposta all'entrata dello studio;<br/>
                            6) di aver ricevuto dalla dott.ssa Lorusso informazioni chiare, precise ed esaustive riguardo il
                            trattamento fisioterapico proposto;<br/>
                            7) di aver ricevuto e compreso le informazioni riguardo gli eventuali rischi derivanti dal
                            trattamento fisioterapico proposto;<br/>
                            8) di essere consapevole che il non rispetto delle indicazioni fornite prima durante e dopo i
                            trattamenti proposti possono causare effetti indesiderati;<br/>
                            9) di non essere sottoposto a quarantena domiciliare obbligatoria;<br/>
                            10) di non essere a conoscenza, alla data di sottoscrizione, di eventuali contagi con malattie
                            ritenute ad alto rischio di infezione.</p>
                    </div>
                </div>
            </div>
            <div fxFlex="100%" fxFlexFill>
                <div fxLayout="column">
                    <dix fxFlex="100%" class="titolino"><span *ngIf="!attiva_firma"><button mat-raised-button style="width: 100%" color="primary" (click)="AttivaFirma()">APPONI FIRMA</button></span><span *ngIf="attiva_firma">Firma Paziente <button mat-icon-button (click)="RefreshFirma()"><mat-icon>brush</mat-icon></button></span></dix>
                    <div *ngIf="attiva_firma" fxFlex="100%" class="box-firma"><signature-pad [options]="signaturePadOptions" (onEndEvent)="drawComplete()"></signature-pad></div>
                </div>
            </div>
            <div fxFlex="100%" fxFlexFill>
                <div fxLayout="column">
                    <dix fxFlex="100%" class="titolino"></dix>
                    <div fxFlex="100%"></div>
                </div>
            </div>
        </div>
    </div>
    <div mat-dialog-actions>
        <button mat-button (click)="onNoClick()">Chiudi</button>
        <button mat-button (click)="Salva(data)">Salva senza firma</button>
        <button mat-button (click)="Salva(data)" [disabled]="!firma_inserita">Salva con firma</button>
    </div>