import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpInterceptor, HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {Giorni,Fattura, ImpostazioniFattura, RigaFattura, RESPONSE, CartellaClinica, Paziente} from '../_models';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import html2canvas from 'html2canvas';
import moment from 'moment';
(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;


@Injectable() export class GlobalServices {

		// Dichiarazioni
		jwtHelper: JwtHelperService = new JwtHelperService();

		// Elenco Endopint API
		private baseURL='https://www.gestionale.fisiokineticalorusso.it/API';
		private baseResURL='https://www.gestionale.fisiokineticalorusso.it/res/';

		
		public apiGetUsername=this.baseURL+'/login/utenti';
		public apiPrestazioni=this.baseURL+'/prestazioni/';
		public apiPazienti=this.baseURL+'/pazienti/';
		public apiCorsi=this.baseURL+'/corsi/';	
		public apiDettaglioCorsi=this.baseURL+'/dettaglio_corsi/';	
		public apiGiorni=this.baseURL+'/giorni/';
		public apiIscrizioni=this.baseURL+'/iscrizioni/';	
		public apiAppunti=this.baseURL+'/appunti/';		
		public apiPresenza= this.baseURL+'/presenza/';
		public apiPresenzaBis= this.baseURL+'/presenzabis/';
		public apiCodici= this.baseURL+'/codici/';
		public apiVerifica=this.baseURL+'/verifica/';
		public apiNumeroFattura=this.baseURL+'/numero_fattura/';
		public apiImpostazioniFattura=this.baseURL+'/impostazioni_fattura/';
		public apiFattura=this.baseURL+'/fattura/';
		public apiMailFattura=this.baseURL+'/mail_fattura/';
		public apiFatturePaziente=this.baseURL+'/fatture_paziente/';
		public apiFile=this.baseURL+'/file/';
		public apiAppuntamenti= this.baseURL+'/appuntamento/';
		public apiMeta=this.baseURL+'/meta/';
		public apiPresenzeOGGI=this.baseURL+'/presenze_oggi/';

		impostazioni: ImpostazioniFattura;
			

		intestazione;
		// Funzioni
		constructor (public http: HttpClient) {
			this.CaricaImpostazioni();
		}

		CaricaImpostazioni(){
			this.get_function(this.apiImpostazioniFattura).subscribe(
				(element: ImpostazioniFattura)=>
				{
					this.impostazioni=element;
				}
				)
		}

		post_function(json_pass: string, url_api: string){
            return this.http.post(url_api, json_pass)
            .pipe(map( response => 
                {
                return response
                }
                ))
		}

		put_function(json_pass: string, url_api: string){
			return this.http.put(url_api, json_pass).pipe(map( response => 
                {
                return response
                }
                ))		
		}
		
		get_function(url_api:string){
			return this.http.get(url_api).pipe(map( response => 
                {
                return response
                }
                ))
		}

		delete_function(url_api: string){
			return this.http.delete(url_api).pipe(map( response => 
                {
                return response
                }
                ))
			/*.catch((e) => { });*/
		}

		get_access_level()
		{
			let token=sessionStorage.getItem('token');
			if(token) return this.jwtHelper.decodeToken(token).userdata['access_level'];
			else return 0;
		}

		get_username()
		{
			let token=sessionStorage.getItem('token');
			if(token) return this.jwtHelper.decodeToken(token).userdata['username'];
			else return '';
		}

		get_date(element){
			let x= new Date(element);
			return x.toLocaleDateString('it-IT',{year: 'numeric', month: 'long', day: 'numeric'});

		}

		OpenFile(obj){
			const base64 =obj.obj;
			const fileName = obj.nome;
			const fileBlob = this.dataURItoBlob(base64,fileName);
			const FFile = new File([fileBlob], fileName, { type: obj.tipo });
			const url= window.URL.createObjectURL(FFile);
			window.open(url);
			}

		dataURItoBlob(dataURI,tipo) {
			const byteString = window.atob(dataURI);
			const arrayBuffer = new ArrayBuffer(byteString.length);
			const int8Array = new Uint8Array(arrayBuffer);
			for (let i = 0; i < byteString.length; i++) {
			  int8Array[i] = byteString.charCodeAt(i);
			}
			const blob = new Blob([int8Array], { type: tipo });    
			return blob;
		 }


		get_righe_fattura(righe: Array<RigaFattura>, fattura:Fattura){
			var output=new Array<any>();
			output.push([
				{text: 'Q.tà',alignment:'center',fillColor:'coral',color:'white',bold:true},
				{text: 'Servizio',alignment:'center',fillColor:'coral',color:'white',bold:true},
				{text: 'Prezzo',alignment:'center',fillColor:'coral',color:'white',bold:true},
				{text: 'Sub Totale',alignment:'center',fillColor:'coral',color:'white',bold:true}
			]);
			righe.forEach(riga=>{
				output.push(
					[
						{text: riga.quantita,alignment:'center'},
						[
							{text: riga.descrizione+'\n',bold:true},
							{text:riga.descrizione_fattura, italics:true,fontSize:8}
						],
						{text: riga.prezzo+' €',alignment:'center'},
						{text: (riga.prezzo*riga.quantita)+' €',alignment:'center'}
					]
				)
			});
			output.push(
				[
					{text:' ',border: [false,false,false,false],fontSize:20},
					{text:' ',border: [false,false,false,false],fontSize:20},
					{text:' ',border: [false,false,false,false],fontSize:20},
					{text:' ',border: [false,false,false,false],fontSize:20}
				]
			)
			output.push([
				{text: 'Sub Totale: ',alignment:'right',fillColor:'coral',color:'white',bold:true,colSpan:3},
				{},
				{},
				{text: fattura.totale_lordo+' €',alignment:'center',fillColor:'coral',color:'white',bold:true}
			]);
			if(fattura.tasse>0)
				output.push([
					{text: 'Iva: ',alignment:'right',fillColor:'coral',color:'white',bold:true,colSpan:3},
					{},
					{},
					{text: fattura.tasse+' €',alignment:'center',fillColor:'coral',color:'white',bold:true}
				]);
			if(fattura.bollo>0)
				output.push([
					{text: 'Marca da Bollo: ',alignment:'right',fillColor:'coral',color:'white',bold:true,colSpan:3},
					{},
					{},
					{text: fattura.bollo+' €',alignment:'center',fillColor:'coral',color:'white',bold:true}
				]);
			output.push([
					{text: 'Totale: ',alignment:'right',fillColor:'coral',color:'white',bold:true,colSpan:3},
					{},
					{},
					{text: fattura.totale_netto+' €',alignment:'center',fillColor:'coral',color:'white',bold:true}
				]);
				output.push(
					[
						{text:' ',border: [false,false,false,false],fontSize:7},
						{text:' ',border: [false,false,false,false],fontSize:7},
						{text:' ',border: [false,false,false,false],fontSize:7},
						{text:' ',border: [false,false,false,false],fontSize:7}
					]
				)
			output.push([
					{text: 'Metodo di pagamento: ',alignment:'right',colSpan:3,border: [false,false,false,false],fontSize:9},
					{text:' ',border: [false,false,false,false],fontSize:9},
					{text:' ',border: [false,false,false,false],fontSize:9},
					{text: fattura.tipo_pagamento,alignment:'center',bold:true,italics:true,border: [false,false,false,false],fontSize:9}
				]);

			return output;

		}

		get_riga_bollo(x){
				if(x==0) return "Esente da bollo di 2,00€ per importi inferiori a € 77,47";
				else return "Imposta di bollo assolta sull'originale";
		}

		GeneraFattura(fattura: Fattura,print){
		if(!this.impostazioni) this.CaricaImpostazioni();
		var docDefinition={
			info:{
				title: fattura.tipo_fattura + 'n° ' + fattura.numero_fattura + ' del ' + this.get_date(fattura.data_fattura),
				author:'Dott.ssa Maria Lorusso',
				creator:'Dott.ssa Maria Lorusso',
				producer:'Dott.ssa Maria Lorusso'
			},
			pageSize: 'A4',
			styles:{
				header:{
					fontSize: 18,
					bold: true,
				},
				footer:{
					fontSize: 9,
					italics: true
				},
				right:{
					alignment: 'right'
				},
				defaultStyle:{
					fontSize: 10,
					lineHeight: 1.5,
					font: 'Helvetica'
				},
				boldHeader:{
					bold:true,
					fontSize:12
				}
			},
			content:[
				{
					margin: [0, 0, 0, 10],
					text: fattura.tipo_fattura + ' n° ' + fattura.numero_fattura + ' del ' + this.get_date(fattura.data_fattura),
					style: ['header', 'right']
				},
				{
					image: 'data:image/jpeg;base64,'+ this.impostazioni.img,
					width: 150
				},
				{
					margin: [0, 10, 0, 0],
					columns:[
						{
							width: '40%',
							text: [
								{text: 'Dott.ssa Maria Lorusso\n', style:'boldHeader'},
								{text:'Via Montesano nr. 6, 70010 - Capurso (BA)\nP.Iva 07557310724', style: 'footer'}
							]
						},
						{
							width:'20%',
							text: ''
						},
						{
							width: '40%',
							text: [
								{text: 'Paziente: \n',style:'footer'},
								{text: fattura.paziente.cognome+' '+fattura.paziente.nome+'\n',style:'boldHeader'},
								{text: fattura.paziente.cf+'\n'+fattura.paziente.indirizzo+' - '+fattura.paziente.citta}
							],
							style: 'right'
						}
					]
				},
				{
					margin: [0, 30, 0, 20],
					table:{
						headerRows:1,
						widths:['10%','65%','10%','15%'],
						body: this.get_righe_fattura(fattura.righe,fattura)
					}
				},
				{
					margin:[0,30,0,30],
					layout:'noBorders',
					table:{
						headerRows:0,
						widths:['100%'],
						body:
						[
							[{text:this.impostazioni.regime_fiscale, alignment:'center', fontSize:9}],
							[{text: this.get_riga_bollo(fattura.bollo) ,alignment:'center', fontSize:9}],
							[{text: this.impostazioni.iban, alignment:'center', fontSize:10}]
						]
					}
				}
			],
			footer:{
				text: 'Grazie per aver scelto FisioKinetica - Dott.ssa Maria Lorusso | info@fisiokineticalorusso.it',
				alignment: 'center',
				fontSize:10,
				bold: true
			}

		}
		
		if(print==0) pdfMake.createPdf(docDefinition).open();
		else if(print==1) pdfMake.createPdf(docDefinition).print();
		else pdfMake.createPdf(docDefinition).getBase64(data=>{
			let obj={"fattura": fattura, "base64": data}
			this.post_function(JSON.stringify(obj), this.apiMailFattura).subscribe((resp:RESPONSE)=>{console.log(resp.data)});
		});

		}

		GeneraCartella(cc: CartellaClinica, p: Paziente){
			if(!this.impostazioni) this.CaricaImpostazioni();
			var docDefinition={
				info:{
					title: p.cognome + ' '+ p.nome + ' - Cartella Clinica del ' + this.get_date(cc.data),
					author:'Dott.ssa Maria Lorusso',
					creator:'Dott.ssa Maria Lorusso',
					producer:'Dott.ssa Maria Lorusso'
				},
				pageSize: 'A4',
				pageMargins: [10, 115, 10, 40],
				styles:{
					header:{
						fontSize: 14,
						bold: true,
						//background: '#0070c0',
						alignment: 'right',
						color: '#ffffff',
						characterSpacing: 1.5
					},

					header1:{
						fontSize: 18,
						bold: true,
						color: '#ffc000',
					},
					footer:{
						fontSize: 9,
						italics: true
					},
					right:{
						alignment: 'right'
					},
					defaultStyle:{
						fontSize: 10,
						lineHeight: 1.5,
						font: 'Helvetica'
					},
					boldHeader:{
						bold:true,
						fontSize:12
					}
				},
				footer:
					function(currentPage, pageCount) {
						var columns= [
							{
								text: 'Dott.ssa Maria Lorusso - Via Montesano nr. 6, 70010 Capurso(BA) | Iscritta all\'albo TSRM-PSTR Bari al nr. 8 | P.Iva 07557310724',
								margin:[15,0,0,0],
								style: 'footer'
								
							},
							{
								text: 'Pag. ' + currentPage.toString() + ' di ' + pageCount,
								style: 'footer',
								margin:[0,0,15,0],
								alignment: 'right'
							}
						]
						return  columns;
					},
				header:{
					table:
					{
						widths:['auto','*'],
						body:[
							[{
							image: 'data:image/jpeg;base64,'+ this.impostazioni.img,
							width: 100,
							margin:[20,15,0,10]
							},
							{
							text: 'CARTELLA CLINICA FISIOTERAPICA',
							margin:[0,65,20,0],
							style: ['header1', 'right']
							}]
						]
					},
					layout:{
						hLineColor: '#ffc000',
						vLineColor: '#ffffff',
						hLineWidth: function(i,node){if(i>0)return 2; else return 0},
						vLineWidth: function(i,node){return 2},
					}
					},
				content:[
					{
						table:{
							widths:['30%'],
							body:
								[
								[{text:'ANAGRAFICA', style: 'header',fillColor: '#0070c0',}]
								]
							},
							layout:{
								hLineColor: '#ffc000',
								vLineColor: '#ffc000',
								hLineWidth: function(i,node){return 2.5},
								vLineWidth: function(i,node){return 2.5},

							}
						
					},
					{
						margin:[10,5,15,0],
						headerRows: 0,
						table:{
							widths:['auto','*'],
							body:[
								[{text: 'Cognome e Nome: ', bold: true, alignment: 'right'}, {text: p.cognome+' '+p.nome, alignment: 'center'}],
								[{text: 'Codice Fiscale: ', bold: true, alignment: 'right'},{text: p.cf, alignment: 'center'}],
								[{text: 'Indirizzo di Residenza: ', bold: true, alignment: 'right'},{text: p.indirizzo+' - '+p.citta, alignment: 'center'}],
								[{text: 'Recapiti: ', bold: true, alignment: 'right'},{text: p.telefono+' - '+p.email, alignment: 'center'}],
							],
						},
						layout:{
							hLineColor: '#000000',
							vLineColor: '#000000',
							hLineWidth: function(i,node){return 0.2},
							vLineWidth: function(i,node){return 0},

						}
					},
					{
						margin:[0,10,0,0],
						table:{
							widths:['30%'],
							body:
								[
								[{text:'ANAMNESI', style: 'header',fillColor: '#0070c0',}]
								]
							},
							layout:{
								hLineColor: '#ffc000',
								vLineColor: '#ffc000',
								hLineWidth: function(i,node){return 2.5},
								vLineWidth: function(i,node){return 2.5},

							}
						
					},
					{
						margin:[10,5,15,0],
						headerRows: 1,
						table:{
							widths:['*'],
							heights: function(row){
								if(row){if(!cc.disturbo) return 60}
								else return 20
							},
							body:[
								[{text: 'Disturbo lamentato dal paziente', bold: true, alignment: 'center'}],
								[{text: cc.disturbo}],
							],
						}
					},
					{
						margin:[10,5,15,0],
						headerRows: 1,
						table:{
							widths:['*'],
							heights: function(row){
								if(row){if(!cc.patologie_correlate) return 60}
								else return 20
							},
							body:[
								[{text: 'Patologie pregresse correlate', bold: true, alignment: 'center'}],
								[{text: cc.patologie_correlate}],
							],
						}
					},
					{
						margin:[10,5,15,0],
						headerRows: 1,
						table:{
							widths:['*'],
							heights: function(row){
								if(row){if(!cc.esami) return 60}
								else return 20
							},
							body:[
								[{text: 'Esami diagnostici effettuati', bold: true, alignment: 'center'}],
								[{text: cc.esami}],
							],
						}
					},
					{
						margin:[10,5,15,0],
						headerRows: 1,
						table:{
							widths:['*'],
							heights: function(row){
								if(row){if(!cc.terapia_correlata) return 60}
								else return 20
							},
							body:[
								[{text: 'Terapia farmacologica in corso per il disturbo lamentato', bold: true, alignment: 'center'}],
								[{text: cc.terapia_correlata}],
							],
						}
					},
					{
						pageBreak:'before',
						//margin:[0,0,0,0],
						table:{
							widths:['30%'],
							body:
								[
								[{text:'STORIA CLINICA', style: 'header',fillColor: '#0070c0',}]
								]
							},
							layout:{
								hLineColor: '#ffc000',
								vLineColor: '#ffc000',
								hLineWidth: function(i,node){return 2.5},
								vLineWidth: function(i,node){return 2.5},

							}
						
					},
					{
						margin:[10,5,15,0],
						headerRows: 1,
						table:{
							widths:['*'],
							heights: function(row){
								if(row){if(!cc.altre_patologie) return 60}
								else return 20
							},
							body:[
								[{text: 'Altre patologie conosciute', bold: true, alignment: 'center'}],
								[{text: cc.altre_patologie}],
							],
						}
					},
					{
						margin:[10,5,15,0],
						headerRows: 1,
						table:{
							widths:['*'],
							heights: function(row){
								if(row){if(!cc.familiarita) return 60}
								else return 20
							},
							body:[
								[{text: 'Familiarità', bold: true, alignment: 'center'}],
								[{text: cc.familiarita}],
							],
						}
					},
					{
						margin:[10,5,15,0],
						headerRows: 1,
						table:{
							widths:['*'],
							heights: function(row){
								if(row){if(!cc.altre_terapie) return 60}
								else return 20
							},
							body:[
								[{text: 'Terapia farmacologica in corso', bold: true, alignment: 'center'}],
								[{text: cc.altre_terapie}],
							],
						}
					},
					{
						margin:[10,5,15,0],
						headerRows: 1,
						table:{
							widths:['*'],
							heights: function(row){
								if(row){if(!cc.interventi) return 60}
								else return 20
							},
							body:[
								[{text: 'Interventi chirurgici', bold: true, alignment: 'center'}],
								[{text: cc.interventi}],
							],
						}
					},
					{
						margin:[10,5,15,0],
						headerRows: 1,
						table:{
							widths:['30%','10%','60%'],
							body:[
								['','',{text: 'Note', style: 'footer'}],
								[{text:'Gravidanza', bold: true},this.checkContent(cc.gravidanza),this.checkContent(cc.gravidanza_note)],
								[{text:'Portatore Peacemaker', bold: true},this.checkContent(cc.peacemaker),this.checkContent(cc.peacemaker_note)],
								[{text:'Placche metalliche', bold: true},this.checkContent(cc.placche),this.checkContent(cc.placche_note)],
								[{text:'Protesi', bold: true},this.checkContent(cc.protesi),this.checkContent(cc.protesi_note)],
								[{text:'Paziente Oncologico', bold: true},this.checkContent(cc.oncologico),this.checkContent(cc.oncologico_note)],
								[{text:'Paziente dializzato', bold: true},this.checkContent(cc.dializzato),this.checkContent(cc.dializzato_note)],
								[{text:'Allergie', bold: true},this.checkContent(cc.allergie),this.checkContent(cc.allergie_note)],
							]
						}
					},
					{
						margin:[10,5,15,0],
						headerRows: 1,
						table:{
							widths:['*'],
							heights: function(row){
								if(row){if(!cc.note) return 60}
								else return 20
							},
							body:[
								[{text: 'Note', bold: true, alignment: 'center'}],
								[{text: cc.note}],
							],
						}
					},
					{
						pageBreak:'before',
						//margin:[0,0,0,0],
						table:{
							widths:['50%'],
							body:
								[
								[{text:'VALUTAZIONE FISIOTERAPICA', style: 'header',fillColor: '#0070c0',}]
								]
							},
							layout:{
								hLineColor: '#ffc000',
								vLineColor: '#ffc000',
								hLineWidth: function(i,node){return 2.5},
								vLineWidth: function(i,node){return 2.5},

							}
						
					},
					{
						margin:[10,5,15,0],
						headerRows: 1,
						table:{
							widths:['*'],
							heights: function(row){
								if(row){if(!cc.osservazione_posturale) return 60}
								else return 20
							},
							body:[
								[{text: 'Osservazione Posturale', bold: true, alignment: 'center'}],
								[{text: cc.osservazione_posturale}],
							],
						}
					},
					{
						margin:[10,5,15,0],
						headerRows: 1,
						table:{
							widths:['*'],
							heights: function(row){
								if(row){if(!cc.segni) return 60}
								else return 20
							},
							body:[
								[{text: 'Segni/Sintomi', bold: true, alignment: 'center'}],
								[{text: cc.segni}],
							],
						}
					},
					{
						margin:[10,5,15,0],
						headerRows: 1,
						table:{
							widths:['*'],
							heights: function(row){
								if(row){if(!cc.test_valutazione) return 60}
								else return 20
							},
							body:[
								[{text: 'Test di Valutazione', bold: true, alignment: 'center'}],
								[{text: cc.test_valutazione}],
							],
						}
					},
					{
						margin:[10,5,15,0],
						headerRows: 1,
						table:{
							widths:['*'],
							heights: function(row){
								if(row){if(!cc.trattamento) return 60}
								else return 20
							},
							body:[
								[{text: 'Tipo di trattamento', bold: true, alignment: 'center'}],
								[{text: cc.trattamento}],
							],
						}
					},
					{
						margin:[10,5,15,0],
						headerRows: 1,
						table:{
							widths:['*'],
							heights: function(row){
								if(row){if(!cc.obiettivo) return 60}
								else return 20
							},
							body:[
								[{text: 'Obiettivo di trattamento', bold: true, alignment: 'center'}],
								[{text: cc.obiettivo}],
							],
						}
					},
					{
						pageBreak:'before',
						text: 'Sottoscrivendo il presente documento dichiaro che: ',
						alignment: 'center',
						margin:[0,10,0,10]
					},
					{
						margin:[15,10,15,10],
						ol:[
							'che tutte le informazioni fornite sono state riportate in maniera esatta dalla dott.ssa Maria Lorusso;',
							'che tutte le informazioni fornite al momento della sottoscrizione del presente documento risultano vere;',
							'che non sussiste nessuna altra causa di impedimento alla terapia proposta taciuta alla dott.ssa Lorusso;',
							'che nel caso venga a conoscenza di nuove informazioni sulla mia salute importanti dal punto di vista fisioterapico metterò al corrente la dott.ssa Lorusso per aggiornare il presente documento;',
							'di aver preso visione dell\'informativa della Privacy esposta all\'entrata dello studio;',
							'di aver ricevuto dalla dott.ssa Lorusso informazioni chiare, precise ed esaustive riguardo il trattamento fisioterapico proposto;',
							'di aver ricevuto e compreso le informazioni riguardo gli eventuali rischi derivanti dal trattamento fisioterapico proposto;',
							'di essere consapevole che il non rispetto delle indicazioni fornite prima durante e dopo i trattamenti proposti possono causare effetti indesiderati;',
							'di non essere sottoposto a quarantena domiciliare obbligatoria;',
							'di non essere a conoscenza, alla data di sottoscrizione, di eventuali contagi con malattie ritenute ad alto rischio di infezione.'
						]
					},
					{
						margin:[15,10,0,10],
						text: 'Capurso, lì '+ moment(cc.data).format("DD/MM/YYYY - HH:mm")
					},
					{
						margin:[0,10,15,10],
						text: 'Firma del paziente',
						alignment: 'right',
						italics: true
					},
					this.getFirma(cc.firma)
					
				]
			}

			pdfMake.createPdf(docDefinition).open();

		}

		GeneraExportFatture(fatture: any,data_inizio,data_fine){
			var docDefinition={
				info:{
					title: "export Fatture Dott.ssa Maria Lorusso dal "+this.get_date(data_inizio)+' al '+this.get_date(data_fine),
					author:'Dott.ssa Maria Lorusso',
					creator:'Dott.ssa Maria Lorusso',
					producer:'Dott.ssa Maria Lorusso'
				},
				pageSize: 'A4',
				pageOrientation: 'landscape',
				styles:{
					header1:{
						fontSize: 10,
						bold: true,
						alignment: 'center',
						characterSpacing: 1.5
					},
					right:{
						alignment: 'right'
					},
					boldHeader:{
						bold:true,
						fontSize:14,
						alignment: 'center'
					}
				},
				content:[
					{
						text: "Export Fatture Dott.ssa Maria Lorusso dal "+this.get_date(data_inizio)+' al '+this.get_date(data_fine),
						style: 'boldHeader'
					},
					{
						margin:[0,15,0,0],
						table:{
							headerRows:1,
							widths:['auto','auto','auto','auto','auto','auto','auto'],
							body: this.getDettaglio(fatture)
						},
						layout: {
							fillColor: function (rowIndex, node, columnIndex) {
								return (rowIndex % 2 === 0) ? '#ffeac1' : '#ffc946';
							}
						}
					}
				]
			}
			pdfMake.createPdf(docDefinition).open();
		}

		getDettaglio(fatture){
			let x=[];
			x.push([{text: 'Tipo Documento', style:'header1'},{text: 'Num.', style:'header1'},{text: 'Data', style:'header1'},{text: 'Tot.', style:'header1'},{text: 'Modalità di pagamento', style:'header1'},{text: 'Paziente', style:'header1'},{text: 'Dettaglio', style:'header1'}])

			fatture.forEach(fattura=>{
				let righe=""
				fattura.righe.forEach(riga=>righe+=riga.quantita+ 'x' + riga.descrizione+' - '+ riga.prezzo+'€, ');
				x.push([fattura.tipo_fattura,fattura.numero_fattura,moment(fattura.data_fattura).format('DD/MM/YYYY'),{text:fattura.totale_netto+'€'},fattura.tipo_pagamento, {text: fattura.paziente.cognome+' '+ fattura.paziente.nome+ '-' +fattura.paziente.cf +'-'+ fattura.paziente.indirizzo+', '+fattura.paziente.citta}, righe]);
				//console.log(x);
			});
			return x;
		}

		checkContent(x){
			if(x) return x;
			else return '';
		}

		getFirma(signature){
			let firma={
				image: signature,
				width: 300,
				alignment: 'right'
			};

			if(signature!='') return firma;
			else return {text:'Non ancora apposta', alignment:'right', italics: true, fontSize: 10};
		}
		
    }