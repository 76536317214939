import { Component, OnInit, Inject , ViewChild} from '@angular/core';
import {Paziente, RESPONSE,Verifica_Iscrizioni,Fattura, AttachFile, CartellaClinica} from '../_models';
import{GlobalServices} from '../_services';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import html2canvas from 'html2canvas';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { SignaturePad } from 'angular2-signaturepad';

(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;

export interface DialogData {
  paziente: Paziente;
}

export interface CartellaClinicaData{
  cartella_clinica: CartellaClinica;
  id: number;
  firma_inserita: boolean;
}

@Component({
  selector: 'app-pazienti',
  templateUrl: './pazienti.component.html',
  styleUrls: ['./pazienti.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class PazientiComponent implements OnInit {
  submitted = false;
  expandedElement: Paziente | null;
  verifica_iscrizioni: Verifica_Iscrizioni;
  fatture_paziente= new Array<Fattura>();
  fileToUpload: File = null;
  array_file= new Array<AttachFile>();
  disabled_button=true;
  cartella_clinica=new CartellaClinica();
  array_cartelle_cliniche= new Array<CartellaClinica>();

  PazientiForm=new FormGroup({
    cognome: new FormControl(),
    nome: new FormControl(),
    data_di_nascita: new FormControl(),
    cf: new FormControl('',[Validators.maxLength(16),Validators.minLength(16)]),
    email: new FormControl('',Validators.email),
    telefono: new FormControl(),
    citta: new FormControl(),
    indirizzo: new FormControl(),
    preferenza: new FormControl(),
  })
  paz= new Array<Paziente>();
  paziente= new Paziente();
  displayedColumns: string[] = ['codice', 'cognome', 'nome', 'data_di_nascita', 'email', 'telefono','azioni'];
  elencoPaziente = new MatTableDataSource(this.paz);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor( public service: GlobalServices, private _snackBar: MatSnackBar, public dialog: MatDialog) { }

  ngOnInit() {
    this.CaricaPazienti();
  }

  get f() { return this.PazientiForm.controls; }

  CaricaCartella(id){
    this.array_cartelle_cliniche=new Array<CartellaClinica>();
    this.service.get_function(this.service.apiMeta+'cc/'+id).subscribe(
      (dati: Array<any>)=> {
        dati.forEach(element=>this.array_cartelle_cliniche.push(JSON.parse(element)))
      })
  }

  AggiornaCartella(id,firma,cc){
    cc.firma="";
    this.OpenCartella(id,firma,cc);
  }

  OpenCartella(id,firma,cc){

    if(cc!='') this.cartella_clinica=cc;
    const dialogRef = this.dialog.open(CartellaClinicaDialog, {
      width: '1000px',
      disableClose: true,
      data: {cartella_clinica: this.cartella_clinica, id: id, firma_inserita: firma}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.service.post_function(JSON.stringify(result.cartella_clinica), this.service.apiMeta+'cc/'+result.id).subscribe(
        (data: RESPONSE)=> {
          if(data.data='OK')
          this._snackBar.open('Operazione eseguita correttamente', 'OK', {
            duration: 3000,
            verticalPosition: 'top'
          });
          this.CaricaCartella(id);
        }
      )
    })
  }

  StampaFattura(element,print){
    this.service.GeneraFattura(element,print);
  }

  getAttachFile(id){
    this.array_file=new Array<AttachFile>();
    this.service.get_function(this.service.apiFile+id).subscribe(
      (ffile: AttachFile[])=>
      {
        this.array_file=ffile;
      }
    )
  }

  changeFileInput(files:FileList){
    this.fileToUpload = files.item(0);
    if(this.fileToUpload.size>3145728) this.disabled_button=true;
    else this.disabled_button=false;
  }

  deleteFileInput(file){
    this.service.delete_function(this.service.apiFile+file.id_allegato).subscribe((data: RESPONSE) => {
      if(data.data='OK')
      this._snackBar.open('Operazione eseguita correttamente', 'OK', {
        duration: 3000,
        verticalPosition: 'top'
      });
      this.array_file.splice(this.array_file.indexOf(file),1);
      }, error => {
        console.log(error);
      });

  }

  handleFileInput(paziente:Paziente) {
    
    const reader = new FileReader();
			reader.readAsDataURL(this.fileToUpload);
			reader.onload = () => {

        let result= reader.result.toString().split(';')[1].split(',')[1];
        let obj={
          'nome':this.fileToUpload.name,
          'tipo':this.fileToUpload.type,
          'obj': result
        }
        this.service.post_function(JSON.stringify(obj),this.service.apiFile+paziente.id_paziente).subscribe((data: RESPONSE) => {
          if(data.data='OK')
          this._snackBar.open('Operazione eseguita correttamente', 'OK', {
            duration: 3000,
            verticalPosition: 'top'
          });
          this.getAttachFile(paziente.id_paziente);
          }, error => {
            console.log(error);
          });
      }
    
}

StampaQr(paziente){
  var docDefinition={
    pageSize: {
      width: 300,
      height: 220
    },
    content: {
    columns:[
    {
      width: 'auto',
      qr: paziente.codice,
      fit:'60'
    },
    {
      width: '*',
      stack:[
        paziente.codice,
       paziente.cognome
      ]
    }
    ],
    columnGap: 10
  }
}
  pdfMake.createPdf(docDefinition).print();
}

  StampaCodice(code){
    let data = document.getElementById(code);  
    html2canvas(data).then(canvas => {
      /*
      SE LO VOGLIO RUOTATO.

      var rotCanvas = document.createElement("canvas");
      console.log(canvas.height+' '+canvas.width)
      rotCanvas.width = canvas.height;
      rotCanvas.height = canvas.width;

    // get context
      var rctx = rotCanvas.getContext("2d");
      rctx.scale(0.6,0.6);

      // translate to center (rotation pivot)
      rctx.translate(-20, 190);

      // rotate -90° (CCW)
      rctx.rotate(-Math.PI * 0.5);
      

      // draw image offset so center of image is on top of pivot
      rctx.drawImage(canvas,0, 0);

    // extract image from rotate canvas
    const contentDataURL = rotCanvas.toDataURL('image/png');*/

    const contentDataURL = canvas.toDataURL('image/png');

    var docDefinition={
      pageSize: {
        width: 170,
        height: 100
      },
      content: [
      {
        image: contentDataURL,
        width: 300
      }
    ]
  }
    pdfMake.createPdf(docDefinition).print();

    }) 
  }

  CaricaPazienti()
  {
      this.service.get_function(this.service.apiPazienti).subscribe(
      (pazienti: Paziente[])=>
      {
        this.elencoPaziente=new MatTableDataSource(pazienti);
        setTimeout(()=>{
          this.elencoPaziente.sort=this.sort;
          this.elencoPaziente.paginator=this.paginator;
          });
      }
    )
  }

  MostraDettagli(obj: Paziente){
    this.cartella_clinica= new CartellaClinica();
    this.disabled_button=true;
    this.expandedElement = this.expandedElement === obj ? null : obj;
    this.service.get_function(this.service.apiVerifica+obj.codice).subscribe(
      (response: Verifica_Iscrizioni)=>
      {
        this.verifica_iscrizioni=response;
      }
    )
    this.service.get_function(this.service.apiFatturePaziente+obj.id_paziente).subscribe(
      (risultati: Array<any>)=>
      {
        risultati.forEach(element=>{
          element.paziente=JSON.parse(element.paziente);
          if(element.righe) element.righe=JSON.parse(element.righe);
        })
        this.fatture_paziente=risultati;
      }
    )
    this.getAttachFile(obj.id_paziente);

    this.CaricaCartella(obj.id_paziente);
    
  }

  Modifica(element){
    this.paziente=element;
  }

  Salva(){
    this.submitted=true;
    if(this.PazientiForm.valid) this.service.post_function(JSON.stringify(this.paziente), this.service.apiPazienti).subscribe(
      (data: RESPONSE)=> {
        if(data.data='OK')
        this._snackBar.open('Operazione eseguita correttamente', 'OK', {
          duration: 3000,
          verticalPosition: 'top'
        });
        this.paziente=new Paziente();
        this.CaricaPazienti();
        this.submitted=false
      }
    )
  }

  applyFilter(event: Event){  
    const filterValue = (event.target as HTMLInputElement).value;
    this.elencoPaziente.filter = filterValue.trim().toLowerCase();
  }

  Elimina(element: Paziente){

    const dialogRef = this.dialog.open(DialogOverviewPazientiDialog, {
      width: '250px',
      data: {paziente: element}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) 
      this.service.delete_function(this.service.apiPazienti+element.id_paziente).subscribe(
        (data: RESPONSE)=> {
          if(data.data='OK')
          this._snackBar.open('Operazione eseguita correttamente', 'OK', {
            duration: 3000,
            verticalPosition: 'top'
          });
          this.paziente=new Paziente();
          this.CaricaPazienti();
        }
      )
    });
  }
}

@Component({
  selector: 'dialog-overview-example-dialog',
  templateUrl: 'dialog-overview-example-dialog.html',
})
export class DialogOverviewPazientiDialog {

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewPazientiDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

}

@Component({
  selector: 'cartella-clinica-dialog',
  templateUrl: 'cartella-clinica-dialog.html',
  styleUrls: ['./pazienti.component.scss'],
})
export class CartellaClinicaDialog {

  @ViewChild(SignaturePad) signaturePad: SignaturePad;

  attiva_firma=false;
  firma_inserita=false;
  firma_mancante=false;

  signaturePadOptions: Object = { 
    'minWidth': 5,
    'canvasWidth': 700,
    'canvasHeight': 100,
    'backgroundColor': 'rgb(240,240,240)',
  };

  constructor(
    public dialogRef: MatDialogRef<CartellaClinicaDialog>,
    @Inject(MAT_DIALOG_DATA) public data: CartellaClinicaData) {
      if(this.data.firma_inserita==true) this.firma_mancante=true;
    }


    ngAfterViewInit() {
       // this.signaturePad is now available
    this.signaturePad.set('minWidth', 5); // set szimek/signature_pad options at runtime
    this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
    }

    drawComplete() {
      // will be notified of szimek/signature_pad's onEnd event
      this.data.cartella_clinica.firma=this.signaturePad.toDataURL();
      this.firma_inserita=true;
    }
  
  onNoClick(): void {
    this.dialogRef.close();
  }

  Salva(data): void{
    this.data.cartella_clinica.data=new Date();
    this.dialogRef.close(this.data);
  }

  RefreshFirma(){
    this.signaturePad.clear();
    this.firma_inserita=false;
  }

  AttivaFirma(){
    this.attiva_firma=!this.attiva_firma;
  }

}
