export class Corsi{
    id_corso;
    descrizione;
    ora_inizio;
    ora_fine;
    numero_partecipanti;
    giorno1;
    giorno2;
    giorno3;
    color= new COLORI();
}

export class COLORI{
    primary;
    secondary;
}